
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    Editorial,
  },
  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = target.value;

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "beneficiariesValue",
        value,
      });
    };

    const options = computed(() => [
      {
        label: "Working for the social enterprise, with training and salaries",
        value: "Production",
      },
      {
        label:
          "Being put in contact with donors who directly donate to beneficiaries or with customers who directly pay beneficiaries for what they produce",
        value: "End Customers",
      },
      {
        label:
          "Receiving donations from the social enterprise, as charities and non-profit organisations",
        value: "Means",
      },
    ]);

    return {
      handleSubmit,
      options,
    };
  },
});

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import {
  State as LocalState,
  allSector,
  sectors,
  Sectors,
  Sector,
} from "./state";

// getter arrow function interfaces
interface GetSector {
  (slug: string): Sector | undefined;
}

// Getters types
export type Getters = {
  getSectors(): Sectors;
  getSectorsWithAll(): Sectors;
  getSectorFromSlug(state: LocalState): GetSector;
  getSectorFromName(state: LocalState): GetSector;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getSectors: () => {
    return sectors;
  },
  getSectorsWithAll: () => {
    return { all: allSector, ...sectors };
  },
  getSectorFromSlug: () => (slug: string) => {
    return sectors[slug];
  },
  getSectorFromName: () => (name: string) => {
    return Object.values(sectors).find((sector) => sector.slug === name);
  },
};


import { defineComponent, Component, computed, ref, watch } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import { CarouselHTMLElement } from "vue3-carousel-types";
import { useStore } from "@/store";
import SlideIntro from "./SlideIntro.vue";
import SlideSocial from "./SlideSocial.vue";
import SlideSectorProfit from "./SlideSectorProfit.vue";
import SlideSectorSocialEnterprise from "./SlideSectorSocialEnterprise.vue";
import SlideCustomisation from "./SlideCustomisation.vue";
import SlideInfluence from "./SlideInfluence.vue";
import SlideBeneficiaries from "./SlideBeneficiaries.vue";
import SlideBeneficiariesCustomers from "./SlideBeneficiariesCustomers.vue";
import SlideBeneficiariesValue from "./SlideBeneficiariesValue.vue";
import SlideProblemDomain from "./SlideProblemDomain.vue";
import SlideComplete from "./SlideComplete.vue";
import Button from "@/components/ui/button/Button.vue";
import ArrowLeftIcon from "@/assets/images/icon-arrow-left.svg";
import ArrowCcwIcon from "@/assets/images/icon-arrow-ccw.svg";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    SlideIntro,
    SlideSocial,
    SlideSectorProfit,
    SlideSectorSocialEnterprise,
    SlideCustomisation,
    SlideInfluence,
    SlideBeneficiaries,
    SlideBeneficiariesCustomers,
    SlideBeneficiariesValue,
    SlideProblemDomain,
    SlideComplete,
    Button,
    ArrowLeftIcon,
    ArrowCcwIcon,
  },
  setup() {
    const store = useStore();

    const slides = computed(() => store.state.businessModelFinder.slides);
    const slideComponents = computed(() => {
      const slideComponents: Component[] = [];
      slides.value.forEach((slide) => {
        switch (slide) {
          case "intro":
            slideComponents.push(SlideIntro);
            break;
          case "social":
            slideComponents.push(SlideSocial);
            break;
          case "sectorProfit":
            slideComponents.push(SlideSectorProfit);
            break;
          case "influence":
            slideComponents.push(SlideInfluence);
            break;
          case "sectorSocialEnterprise":
            slideComponents.push(SlideSectorSocialEnterprise);
            break;
          case "problemDomain":
            slideComponents.push(SlideProblemDomain);
            break;
          case "beneficiaries":
            slideComponents.push(SlideBeneficiaries);
            break;
          case "beneficiariesCustomers":
            slideComponents.push(SlideBeneficiariesCustomers);
            break;
          case "beneficiariesValue":
            slideComponents.push(SlideBeneficiariesValue);
            break;
          case "customisation":
            slideComponents.push(SlideCustomisation);
            break;
          case "complete":
            slideComponents.push(SlideComplete);
            break;
        }
      });
      return slideComponents;
    });

    const transitionTime = 300;

    const bmfCarousel = ref<CarouselHTMLElement>();

    // When the collection of slides is updated, transition to the last slide
    // (if it isn't already shown).
    // The "deep" param is needed to watch nested properties (slides array data) for changes
    // and "flush: 'post'" ensures the Vue components (slides in the carousel) update
    // before the watcher callback is triggered (the slide transition)
    watch(
      () => slides.value,
      () => {
        if (!bmfCarousel.value) return;
        bmfCarousel.value?.updateCarousel();
        bmfCarousel.value?.slideTo(slides.value.length - 1);
      },
      { deep: true, flush: "post" }
    );

    const hideBack = computed(
      () =>
        // hide back on the first slide
        slides.value.length === 1 ||
        // hide back on the last (summary) slide
        slides.value[slides.value.length - 1] === "complete"
    );

    // we transition the carousel before removing the last slide as otherwise
    // we would be removing the final slide immediately and so would have
    // nothing to transition from
    const handleBack = () => {
      bmfCarousel.value?.prev();
      setTimeout(() => {
        store.commit("businessModelFinder/BACK");
        bmfCarousel.value?.restartCarousel();
      }, transitionTime);
    };

    // we transition the carousel before resetting the carousel, otherwise we
    // would be removing all slides immediately and so would have nothing to
    // transition from
    const handleReset = () => {
      bmfCarousel.value?.slideTo(0);
      setTimeout(() => {
        store.commit("businessModelFinder/RESET_ALL");
        bmfCarousel.value?.restartCarousel();
      }, transitionTime);
    };

    return {
      bmfCarousel,
      transitionTime,
      handleBack,
      handleReset,
      hideBack,
      slides,
      slideComponents,
    };
  },
});

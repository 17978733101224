export interface Types {
  [slug: string]: string;
}

export interface Item {
  title: string;
  text: Types;
}

export type Thinking = Array<Item>;

export const thinking: Thinking = [
  {
    title: "People",
    text: {
      product:
        "Imagine if you were not limited to the current leadership model, how might you address the challenge?",
      solutions:
        "Imagine if you were not limited to the current leadership model, how might you address the challenge?",
      matchmaking:
        "Imagine if you were not limited to the current leadership model, how might you address the challenge?",
      "multi-sided":
        "Imagine if you were not limited to the current leadership model, how might you address the challenge?",
    },
  },
  {
    title: "Place",
    text: {
      product:
        "How would you approach this challenge if you were not restricted to the physical venue of your business?",
      solutions:
        "How would you approach this challenge if you were not restricted to the physical venue of your business?",
      matchmaking:
        "How would you approach this challenge if you were not restricted to the physical venue of your business?",
      "multi-sided":
        "How would you approach this challenge if you were not restricted to the physical venue of your business?",
    },
  },
  {
    title: "Organisation",
    text: {
      product:
        "Imagine working around the limitation of the current organisational structure, how might that help you?",
      solutions:
        "Imagine not being limited to the limitations of the current production process, how might that help you?",
      matchmaking:
        "Imagine not being limited to the current market — can collected data be used to enter a complementary market? ",
      "multi-sided":
        "How might you approach this challenge if you weren’t constrained to your current market?",
    },
  },
  {
    title: "Other",
    text: {
      product:
        "How might you approach this challenge if you weren’t constrained by financial capital?",
      solutions:
        "How might you approach this challenge if you weren’t constrained by the current limits of technology?",
      matchmaking:
        "How might you approach this challenge if your features weren’t constrained because of IP rights of competitors?",
      "multi-sided":
        "Imagine you are not restricted to your current data, what data could you collect to improve your offering so that others cannot compete?",
    },
  },
];

// Declare state
export type State = Record<string, never>; // {};

// Create initial state
export const state = {} as State;


import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const year = new Date().getFullYear();

    return {
      year,
    };
  },
});

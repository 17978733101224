
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    img: {
      type: String,
      required: true,
    },
  },
  setup() {
    const load = (imgPath: string) =>
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`@/assets/images/${imgPath}`).default;

    return {
      load,
    };
  },
});

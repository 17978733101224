import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", {
      [`button--type-${_ctx.type}`]: _ctx.type,
      [`button--size-${_ctx.size}`]: _ctx.size,
      [`button--rounded-${_ctx.rounded}`]: _ctx.rounded,
      [`button--disabled`]: _ctx.disabled,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { ref: "tippyRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.closeIcon)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "hover:cursor-pointer px-2 py-2 absolute right-0 top-0 text-cebai-gray hover:text-black",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_XIcon, { class: "fill-current" })
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 512))
}
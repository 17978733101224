// see:
// https://dev.to/shubhadip/vue-3-vuex-4-modules-typescript-2i2o

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import {
  businessModels,
  Store as BusinessModelsStore,
  State as BusinessModelsState,
} from "./business-models";
import {
  businessModelFinder,
  Store as BusinessModelFinderStore,
  State as BusinessModelFinderState,
} from "./business-model-finder";
import {
  caseStudies,
  Store as CaseStudiesStore,
  State as CaseStudiesState,
} from "./case-studies";
import {
  caseStudiesList,
  Store as CaseStudiesListStore,
  State as CaseStudiesListState,
} from "./case-studies-list";
import {
  caseStudyEntities,
  Store as CaseStudyEntitiesStore,
  State as CaseStudyEntitiesState,
} from "./case-study-entities";
import {
  resources,
  Store as ResourcesStore,
  State as ResourcesState,
} from "./resources";
import { labels, Store as LabelsStore, State as LabelsState } from "./labels";
import {
  messages,
  Store as MessagesStore,
  State as MessagesState,
} from "./messages";
import {
  recentChallenges,
  Store as RecentChallengesStore,
  State as RecentChallengesState,
} from "./recent-challenges";
import {
  inspirationList,
  Store as InspirationListStore,
  State as InspirationListState,
} from "./inspiration-list";
import {
  sectors,
  Store as SectorsStore,
  State as SectorsState,
} from "./sectors";
import {
  transformThinking,
  Store as TransformThinkingStore,
  State as TransformThinkingState,
} from "./transform-thinking";

export type State = {
  businessModels: BusinessModelsState;
  businessModelFinder: BusinessModelFinderState;
  caseStudies: CaseStudiesState;
  caseStudiesList: CaseStudiesListState;
  caseStudyEntities: CaseStudyEntitiesState;
  resources: ResourcesState;
  labels: LabelsState;
  recentChallenges: RecentChallengesState;
  inspirationList: InspirationListState;
  messages: MessagesState;
  sectors: SectorsState;
  transformThinking: TransformThinkingState;
};

export type Store = BusinessModelsStore<Pick<State, "businessModels">> &
  BusinessModelFinderStore<Pick<State, "businessModelFinder">> &
  CaseStudiesStore<Pick<State, "caseStudies">> &
  CaseStudiesListStore<Pick<State, "caseStudiesList">> &
  CaseStudyEntitiesStore<Pick<State, "caseStudyEntities">> &
  ResourcesStore<Pick<State, "resources">> &
  LabelsStore<Pick<State, "labels">> &
  MessagesStore<Pick<State, "messages">> &
  RecentChallengesStore<Pick<State, "recentChallenges">> &
  InspirationListStore<Pick<State, "inspirationList">> &
  SectorsStore<Pick<State, "sectors">> &
  TransformThinkingStore<Pick<State, "transformThinking">>;

// persisted data (kept in localStorage)
const persistedState = createPersistedState({
  paths: ["inspirationList"],
});

export const store = createStore({
  modules: {
    businessModels,
    businessModelFinder,
    caseStudies,
    caseStudiesList,
    caseStudyEntities,
    resources,
    labels,
    messages,
    recentChallenges,
    inspirationList,
    sectors,
    transformThinking,
  },
  plugins: [persistedState],
});

export function useStore(): Store {
  return store as Store;
}

export default store;

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import {
  CaseStudyDetail,
  CaseStudySummaries,
  State as LocalState,
} from "./state";

type GetSummaries = {
  (limit: undefined | number): CaseStudySummaries;
};

// Getters types
export type Getters = {
  getIsLoading(state: LocalState): boolean;
  getCaseStudyDetail(state: LocalState): CaseStudyDetail | null;
  getCaseStudySummaries(state: LocalState): GetSummaries;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getCaseStudyDetail: (state) => {
    return state.caseStudyDetail;
  },
  getCaseStudySummaries:
    (state) =>
    (limit = 1000) => {
      return state.caseStudySummaries.slice(0, limit);
    },
};

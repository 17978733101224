
import { defineComponent } from "vue";
import TinySlider from "@/components/ui/tiny-slider/TinySlider.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import CaseStudyCard from "./CaseStudyCard.vue";

export default defineComponent({
  components: {
    LoadingIcon,
    CaseStudyCard,
    TinySlider,
  },
  props: {
    caseStudies: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const tinySliderOptions = {
      autoHeight: false,
      mouseDrag: true,
      touch: true,
      autoplay: false,
      loop: false,
      controls: true,
      nav: true,
      items: 1,
      responsive: {
        640: {
          items: 2,
        },
        1023: {
          items: 3,
        },
      },
    };

    return {
      tinySliderOptions,
    };
  },
});


import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import InfoPanelOverlay from "@/components/ui/info-panel-overlay/InfoPanelOverlay.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Svg from "@/components/ui/svg/Svg.vue";

export default defineComponent({
  components: {
    InfoPanelOverlay,
    Editorial,
    Svg,
  },

  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = parseInt(target.value, 10);

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "influence",
        value,
      });
    };

    const values = [1, 2, 3, 4];

    const overlayIsShown = ref(false);
    const showOverlay = () => (overlayIsShown.value = true);
    const hideOverlay = () => (overlayIsShown.value = false);

    return {
      handleSubmit,
      values,
      overlayIsShown,
      showOverlay,
      hideOverlay,
    };
  },
});


import { defineComponent, ref } from "vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Button from "@/components/ui/button/Button.vue";
import PlayIcon from "@/assets/images/icon-play.svg";
import XIcon from "@/assets/images/icon-x.svg";

export default defineComponent({
  components: {
    Svg,
    Editorial,
    Button,
    PlayIcon,
    XIcon,
  },
  setup() {
    // modal
    const isShown = ref(false);
    const showModal = () => (isShown.value = true);
    const closeModal = () => (isShown.value = false);

    return {
      isShown,
      showModal,
      closeModal,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shadow bg-white rounded-2xl flex flex-col h-full text-cebai-primary relative overflow-hidden" }
const _hoisted_2 = { class: "p-5 grow flex flex-col justify-start" }
const _hoisted_3 = { class: "text-xl font-medium mb-2" }
const _hoisted_4 = { class: "text-sm mb-2" }
const _hoisted_5 = { class: "ml-3 absolute right-4 top-4" }
const _hoisted_6 = {
  key: 0,
  class: "absolute right-0 w-40"
}
const _hoisted_7 = { class: "shadow bg-white rounded-md p-2 flex flex-col" }
const _hoisted_8 = { class: "w-4 h-4 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CaseStudyEntities = _resolveComponent("CaseStudyEntities")!
  const _component_MoreVerticalIcon = _resolveComponent("MoreVerticalIcon")!
  const _component_Svg = _resolveComponent("Svg")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ThumbsUpIcon = _resolveComponent("ThumbsUpIcon")!
  const _component_ThumbsDownIcon = _resolveComponent("ThumbsDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.caseStudy.name), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.caseStudy.summary), 1),
      _createVNode(_component_router_link, {
        class: "mt-auto text-xs font-medium text-cebai-primary hover:text-cebai-gray",
        to: { name: 'case-study', params: { slug: _ctx.caseStudy.slug } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Learn more > ")
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["px-4 py-2", `bg-cebai-model-${_ctx.caseStudy.model.toLowerCase()}`])
    }, [
      _createVNode(_component_CaseStudyEntities, { "case-study": _ctx.caseStudy }, null, 8, ["case-study"])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "py-1 px-px text-cebai-gray rounded-lg transition duration-300 hover:bg-cebai-gray-light",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createVNode(_component_MoreVerticalIcon, { class: "fill-current" })
      ]),
      (_ctx.dropdownIsShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                type: _ctx.isAdded ? 'default' : 'text',
                size: "small",
                class: "justify-end mb-1",
                onClick: _ctx.handleAddToList
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add"),
                  (_ctx.isAdded)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("ed")
                      ], 64))
                    : _createCommentVNode("", true),
                  _createTextVNode(" to list "),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_Svg, {
                      img: "inspiration-bulb.svg",
                      class: "text-black fill-current"
                    })
                  ])
                ]),
                _: 1
              }, 8, ["type", "onClick"]),
              _createVNode(_component_Button, {
                type: "text",
                size: "small",
                class: "justify-end mb-1",
                onClick: _ctx.handleMoreLikeThis
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" More like this "),
                  _createVNode(_component_ThumbsUpIcon, { class: "w-4 h-4 ml-2 fill-current" })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_Button, {
                type: "text",
                size: "small",
                class: "justify-end",
                onClick: _ctx.handleLessLikeThis
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Less like this "),
                  _createVNode(_component_ThumbsDownIcon, { class: "w-4 h-4 ml-2 fill-current" })
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}

import { defineComponent, provide, ref } from "vue";

export default defineComponent({
  components: {},
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // don't use toRef here:
    // we only want to use the active tab prop to set the initial value
    // rather than tracking it as a reactive prop
    const activeTab = ref(props.activeTab);

    const updateActiveTab = (slug: string) => {
      activeTab.value = slug;
    };

    provide("activeTab", activeTab);
    provide("updateActiveTab", updateActiveTab);

    return {};
  },
});

import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, SlideResponse } from "./state";
import { Getters } from "./getters";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & Omit<ActionContext<LocalState, RootState>, "commit" | "getters">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_VALUE](
    { getters, commit }: AugmentedActionContext,
    payload: SlideResponse
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_VALUE]({ commit, getters }, payload) {
    // store value
    commit(MutationTypes.SET_VALUE, payload);

    // proceed to next slide
    const nextSlide = getters.getNextSlide;
    commit(MutationTypes.MOVE_TO_NEXT_SLIDE, nextSlide);
  },
};

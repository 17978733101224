import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Label } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LABEL](state: S, label: Label | null): void;
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LABEL](state: LocalState, label: Label | null) {
    state.label = label;
  },
  [MutationTypes.SET_LOADING](state: LocalState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

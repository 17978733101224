import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-12 p-12 flex justify-center"
}
const _hoisted_2 = { class: "w-8" }
const _hoisted_3 = {
  key: 1,
  class: "-mx-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_CaseStudyCard = _resolveComponent("CaseStudyCard")!
  const _component_TinySlider = _resolveComponent("TinySlider")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoadingIcon)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_TinySlider, { options: _ctx.tinySliderOptions }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseStudies, (caseStudy, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "p-2 w-1/3 flex items-stretch"
              }, [
                _createVNode(_component_CaseStudyCard, { "case-study": caseStudy }, null, 8, ["case-study"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"])
      ]))
}
export type Message = {
  type: string;
  message: string;
};
export type Messages = Array<Message>;

// Declare state
export type State = {
  messages: Messages;
};

// Create initial state
export const state = {
  messages: [],
} as State;

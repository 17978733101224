import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { Sector } from "@/store/sectors/state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_MODEL](state: S, model: string): void;
  [MutationTypes.SET_SECTOR](state: S, sector: Sector): void;
  [MutationTypes.SET_GROUP](state: S, group: string): void;
  [MutationTypes.SET_HEADQUARTERS](state: S, headquarters: string): void;
  [MutationTypes.SET_QUERY](state: S, query: string): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_MODEL](state, model) {
    state.model = model;
  },
  [MutationTypes.SET_SECTOR](state, sector) {
    state.sector = sector;
  },
  [MutationTypes.SET_GROUP](state, group) {
    state.group = group;
  },
  [MutationTypes.SET_HEADQUARTERS](state, headquarters) {
    state.headquarters = headquarters;
  },
  [MutationTypes.SET_QUERY](state, query) {
    state.query = query;
  },
};


import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    Editorial,
  },
  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = target.value;

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "beneficiaries",
        value,
      });
    };

    const beneficiaries = computed(() => [
      "Communities",
      "Civil Society Organisations",
      "Children and Students",
      "People with Vulnerabilities or Victims of Discrimination",
      "Workers",
    ]);

    return {
      handleSubmit,
      beneficiaries,
    };
  },
});

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative z-30 col-span-12 rounded-2xl bg-cebai-tint", {
      'mt-3': _ctx.extraOverlap === true,
      'mt-6': _ctx.extraOverlap === false,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
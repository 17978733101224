import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Challenges } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_CHALLENGES](state: S, challenges: Challenges): void;
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_CHALLENGES](state: LocalState, challenges: Challenges) {
    state.challenges = challenges;
  },
  [MutationTypes.SET_LOADING](state: LocalState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};


import { defineComponent } from "vue";
import Alert from "@/components/ui/alert/Alert.vue";
import Header from "@/components/layout/header/Header.vue";
import Footer from "@/components/layout/footer/Footer.vue";
import Content from "@/components/layout/content/Content.vue";
import InspirationListToggle from "@/components/content/inspiration-list/InspirationListToggle.vue";

export default defineComponent({
  components: {
    Alert,
    Header,
    Footer,
    Content,
    InspirationListToggle,
  },
  props: [],
  setup() {
    return {};
  },
});

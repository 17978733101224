import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

import data from "./data.json";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_BUSINESS_MODELS]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_BUSINESS_MODELS]({ commit }) {
    commit(MutationTypes.SET_LOADING, true);

    // query API with supplied options?
    const businessModels = data;

    commit(MutationTypes.SET_BUSINESS_MODELS, businessModels);
    commit(MutationTypes.SET_LOADING, false);
  },
};

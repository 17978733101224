import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations, InspirationNote } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_INSPIRATIONS]({ commit }: AugmentedActionContext): void;
  [ActionTypes.UPDATE_INSPIRATION_NOTE](
    { commit }: AugmentedActionContext,
    payload: InspirationNote
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_INSPIRATIONS]({ commit }) {
    commit(MutationTypes.SET_LOADING, true);

    // // query API with supplied options
    // const inspirations: any = [];

    setTimeout(() => {
      // commit(MutationTypes.SET_INSPIRATIONS, inspirations);
      commit(MutationTypes.SET_LOADING, false);
    }, 2000);
  },
  [ActionTypes.UPDATE_INSPIRATION_NOTE]({ commit }, payload: InspirationNote) {
    // query API with updated note

    commit(MutationTypes.UPDATE_INSPIRATION_NOTE, payload);
  },
};


import { defineComponent, PropType, ref, onMounted } from "vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import { CaseStudySummary, Entity } from "@/store/case-studies/state";
import Tippy from "@/components/ui/tippy/Tippy.vue";
import CaseStudyEntity from "./CaseStudyEntity.vue";
import Button from "@/components/ui/button/Button.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";

export default defineComponent({
  components: {
    Tippy,
    LoadingIcon,
    CaseStudyEntity,
    Button,
    RefreshIcon,
  },
  props: {
    caseStudy: {
      type: Object as PropType<CaseStudySummary>,
      required: true,
    },
  },
  setup(props) {
    const entities = ref<Entity[]>();
    const entitiesAreLoading = ref(false);
    const handleRefreshEntities = () => selectRandomEntities();

    const selectRandomEntities = () => {
      entitiesAreLoading.value = true;
      setTimeout(() => {
        entitiesAreLoading.value = false;
        const newEntities = [...props.caseStudy.entities];

        // reduce...
        while (newEntities.length > 6) {
          const index = Math.floor(Math.random() * newEntities.length);
          newEntities.splice(index, 1);
        }

        // ...and shuffle
        newEntities.sort(() => (Math.random() > 0.5 ? 1 : -1));

        entities.value = newEntities;
      }, 1000);
    };

    type VisibleSpark = {
      caseStudy: number | null;
      entity: number | null;
    };
    const visibleSpark = ref<VisibleSpark>({
      caseStudy: null,
      entity: null,
    });

    const checkSparkIsVisible = (caseStudy: number, entity: number) => {
      return (
        caseStudy === visibleSpark.value.caseStudy &&
        entity === visibleSpark.value.entity
      );
    };

    const setVisibleSpark = (
      caseStudy: number | null,
      entity: number | null
    ) => {
      visibleSpark.value.caseStudy = caseStudy;
      visibleSpark.value.entity = entity;
    };

    onMounted(() => {
      selectRandomEntities();
    });

    const tippyOptions = {
      interactive: true,
      hideOnClick: true,
      appendTo: document.body,
      maxWidth: 380,
      onHide() {
        setVisibleSpark(null, null);
      },
    };

    return {
      handleRefreshEntities,
      entitiesAreLoading,
      entities,
      tippyOptions,
      checkSparkIsVisible,
      setVisibleSpark,
    };
  },
});

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import {
  State as LocalState,
  BusinessModel,
  businessModelIcons,
  Icon,
} from "./state";

// getter arrow function interfaces
interface GetBusinessModel {
  (slug: string): BusinessModel | null;
}
interface GetIcon {
  (iconType: string): Icon | undefined;
}

// Getters types
export type Getters = {
  getIsLoading(state: LocalState): boolean;
  getBusinessModelFromSlug(state: LocalState): GetBusinessModel;
  getBusinessModelIconFromSlug(state: LocalState): GetIcon;
  getBusinessModelIconFromName(state: LocalState): GetIcon;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getBusinessModelFromSlug: (state) => (slug: string) => {
    if (slug in state.businessModels) {
      return state.businessModels[slug];
    }
    return null;
  },
  getBusinessModelIconFromSlug: () => (slug: string) => {
    return businessModelIcons[slug];
  },
  getBusinessModelIconFromName: () => (name: string) => {
    return Object.values(businessModelIcons).find(
      (businessModelIcon) => businessModelIcon.name === name
    );
  },
};


import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    extraOverlap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});

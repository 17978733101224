import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

import { CaseStudySummaries } from "@/store/case-studies/state";

// Getters types
export type Getters = {
  getCaseStudies(
    state: LocalState,
    getters: Getters,
    rootState: RootState
  ): CaseStudySummaries;
  getGroups(
    state: LocalState,
    getters: Getters,
    rootState: RootState
  ): string[];
  getHeadquarters(
    state: LocalState,
    getters: Getters,
    rootState: RootState
  ): string[];
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getCaseStudies: (state, getters, rootState) => {
    let caseStudies = rootState.caseStudies.caseStudySummaries;

    const { model, group, headquarters, query } = state;
    const { sector } = state;

    if (model) {
      caseStudies = caseStudies.filter(
        (caseStudy) => caseStudy.model === model
      );
    }

    if (sector && sector.name !== "All") {
      caseStudies = caseStudies.filter(
        // fix mismatch between API "other industries" and "other services"
        (caseStudy) =>
          caseStudy.sector === sector.apiSlug.replace("services", "Industries")
      );
    }

    if (group && group !== "All") {
      caseStudies = caseStudies.filter(
        (caseStudy) => caseStudy.group === group
      );
    }

    if (headquarters && headquarters !== "All") {
      caseStudies = caseStudies.filter(
        (caseStudy) => caseStudy.headquarters === headquarters
      );
    }

    if (query) {
      caseStudies = caseStudies.filter(
        (caseStudy) =>
          caseStudy.name.toLowerCase().includes(query) ||
          caseStudy.summary.toLowerCase().includes(query) ||
          caseStudy.description.toLowerCase().includes(query) ||
          caseStudy.sector?.toLowerCase().includes(query) ||
          caseStudy.model?.toLowerCase().includes(query) ||
          caseStudy.headquarters?.toLowerCase().includes(query) ||
          caseStudy.group?.toLowerCase().includes(query)
      );
    }

    return caseStudies;
  },
  getGroups: (state, getters, rootState) => {
    const caseStudies = rootState.caseStudies.caseStudySummaries;
    return [...new Set(caseStudies.map((caseStudy) => caseStudy.group || ""))];
  },
  getHeadquarters: (state, getters, rootState) => {
    const caseStudies = rootState.caseStudies.caseStudySummaries;
    return [
      ...new Set(caseStudies.map((caseStudy) => caseStudy.headquarters || "")),
    ];
  },
};

import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router,
} from "vue-router";

import RootLayout from "@/components/layout/root/Root.vue";
import HomeView from "@/components/views/home/Home.vue";
import BusinessModelView from "@/components/views/business-model/BusinessModel.vue";
import CaseStudyView from "@/components/views/case-study/CaseStudy.vue";
import CookiesView from "@/components/views/cookies/Cookies.vue";
import TermsAndConditionsView from "@/components/views/terms-and-conditions/TermsAndConditions.vue";
import ComponentLibraryView from "@/components/views/component-library/ComponentLibrary.vue";
import ErrorView from "@/components/views/error/Error.vue";

type ScrollPositionNormalized = {
  behavior?: ScrollOptions["behavior"];
  left: number;
  top: number;
};

declare module "vue-router" {
  interface Breadcrumb {
    title: string;
    name?: string;
  }
  interface RouteMeta {
    breadcrumb: Breadcrumb[];
    model?: string;
    scrollPos?: ScrollPositionNormalized;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: RootLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        meta: {
          breadcrumb: [{ title: "Home" }],
          scrollPos: {
            top: 0,
            left: 0,
          },
        },
      },
      {
        path: "business-models/:slug/",
        name: "business-model",
        component: BusinessModelView,
        meta: {
          breadcrumb: [
            { title: "Home", name: "home" },
            { title: "Business Model" },
          ],
        },
        props: (route) => {
          return { slug: route.params.slug || "" };
        },
      },
      {
        path: "case-study/:slug/",
        name: "case-study",
        component: CaseStudyView,
        meta: {
          breadcrumb: [
            { title: "Home", name: "home" },
            { title: "Case Study" },
          ],
        },
        props: (route) => {
          return { slug: route.params.slug || "" };
        },
      },
      {
        path: "cookies/",
        name: "cookies",
        component: CookiesView,
      },
      {
        path: "terms-and-conditions/",
        name: "terms-and-conditions",
        component: TermsAndConditionsView,
      },
      {
        path: "component-library/",
        name: "component-library",
        component: ComponentLibraryView,
      },
    ],
  },
  {
    path: "/error/",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorView,
  },
];

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // ensure start at top when on same page (e.g. logo home clicked)
    if (to.name === from.name) {
      if (to.meta?.scrollPos) {
        to.meta.scrollPos.top = 0;
      }
      return { top: 0, left: 0 };
    }
    // savedPosition: triggered by the browser's back/forward buttons
    // to.meta?.scrollPos: scroll position stored in meta data (if it exists)
    // default position: set as top of page
    return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // store scroll position of page we are leaving
  // used for in-App navigation (not browser navigation)
  if (from.meta?.scrollPos) {
    from.meta.scrollPos.top = document.documentElement.scrollTop;
  }
  return next();
});

export default router;

import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import {
  State as LocalState,
  CaseStudySummaries,
  CaseStudyDetail,
} from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
  [MutationTypes.SET_CASE_STUDY_SUMMARIES](
    state: S,
    caseStudySummaries: CaseStudySummaries
  ): void;
  [MutationTypes.SET_CASE_STUDY_DETAIL](
    state: S,
    caseStudyDetail: CaseStudyDetail | null
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_CASE_STUDY_SUMMARIES](state, caseStudySummaries) {
    state.caseStudySummaries = caseStudySummaries;
  },
  [MutationTypes.SET_CASE_STUDY_DETAIL](state, caseStudyDetail) {
    state.caseStudyDetail = caseStudyDetail;
  },
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "py-12" }
const _hoisted_2 = { class: "absolute bottom-0 left-0 right-0 pb-6 z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Carousel, {
      ref: "bmfCarousel",
      "mouse-drag": false,
      "touch-drag": false,
      transition: _ctx.transitionTime
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slideComponents, (slide, index) => {
          return (_openBlock(), _createBlock(_component_Slide, {
            key: _ctx.slides[index]
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(slide)))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["transition"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex justify-center", { hidden: _ctx.hideBack }])
      }, [
        (_ctx.handleBack)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "light",
              onClick: _ctx.handleBack
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowLeftIcon, { class: "mr-1 fill-current" }),
                _createTextVNode(" Back ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 64))
}
export type Spark = {
  title: string;
  url: string;
  action: string;
  category: string;
  label?: string;
};

export type Sparks = Array<Spark>;

// Declare state
export type State = {
  isLoading: boolean;
  sparks: Sparks;
};

// Create initial state
export const state = {
  isLoading: false,
  sparks: [],
} as State;

import { Store as VuexStore, CommitOptions, Module } from "vuex";
import { State as LocalState, state } from "./state";
import { Mutations, mutations } from "./mutations";
import { State as RootState } from "@/store";
import { Getters, getters } from "./getters";
import { Namespaced } from "../namespaced";

// State type
export type State = LocalState;

type NamespacedMutations = Namespaced<Mutations, "caseStudiesList">;
type NamespacedGetters = Namespaced<Getters, "caseStudiesList">;

// Setup store type
export type Store<S = LocalState> = Omit<VuexStore<S>, "getters" | "commit"> & {
  getters: {
    [K in keyof NamespacedGetters]: ReturnType<NamespacedGetters[K]>;
  };
} & {
  commit<K extends keyof NamespacedMutations>(
    key: K,
    payload?: Parameters<NamespacedMutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<NamespacedMutations[K]>;
};

export const caseStudiesList: Module<LocalState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
};


import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    Editorial,
  },
  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = target.value;

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "beneficiariesCustomers",
        value,
      });
    };

    const options = computed(() => [
      {
        label: "Beneficiaries are customers that pay for a product or service",
        value: "All",
      },
      {
        label: "Beneficiaries and customers are two different groups",
        value: "None",
      },
    ]);

    return {
      handleSubmit,
      options,
    };
  },
});

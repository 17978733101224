
import { defineComponent, PropType } from "vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import { BusinessModel } from "@/store/business-models/state";
import Markdown from "@/components/ui/markdown/Markdown.vue";

export default defineComponent({
  components: {
    Editorial,
    Markdown,
  },
  props: {
    businessModel: {
      type: Object as PropType<BusinessModel>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});

export type Challenge = {
  challenge: string;
};

export type Challenges = Array<Challenge>;

// Declare state
export type State = {
  isLoading: boolean;
  challenges: Challenges;
};

// Create initial state
export const state = {
  isLoading: false,
  challenges: [],
} as State;

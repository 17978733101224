import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  getNextSlide(state: LocalState): string | undefined;
  getBusinessModel(state: LocalState): string;
  getSocialFactor(state: LocalState): string | undefined;
  getIsComplete(): boolean;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getNextSlide: (state) => {
    const currentSlide = state.slides[state.slides.length - 1];
    switch (true) {
      case currentSlide === "intro":
        return "social";
      case currentSlide === "social":
        if (state.values.social > 1) {
          return "sectorSocialEnterprise";
        } else {
          return "sectorProfit";
        }

      // route 1
      case currentSlide === "sectorProfit":
        return "influence";
      case currentSlide === "influence":
        return "customisation";

      // route 2
      case currentSlide === "sectorSocialEnterprise":
        return "problemDomain";
      case currentSlide === "problemDomain":
        return "beneficiaries";
      case currentSlide === "beneficiaries":
        return "beneficiariesCustomers";
      case currentSlide === "beneficiariesCustomers":
        if (state.values.beneficiariesCustomers === "All") {
          return "customisation";
        } else {
          return "beneficiariesValue";
        }
      case currentSlide === "beneficiariesValue":
        if (state.values.beneficiariesValue === "Production") {
          return "customisation";
        } else {
          return "complete";
        }

      // route 1 and route 2
      case currentSlide === "customisation":
        return "complete";
    }
  },
  getBusinessModel: (state) => {
    switch (true) {
      // profit route
      case state.values.social === 1 &&
        state.values.customisation <= 2 &&
        state.values.influence <= 2:
        return "product";
      case state.values.social === 1 &&
        state.values.customisation <= 2 &&
        state.values.influence > 2:
        return "matchmaking";
      case state.values.social === 1 &&
        state.values.customisation > 2 &&
        state.values.influence <= 2:
        return "solutions";
      case state.values.social === 1 &&
        state.values.customisation > 2 &&
        state.values.influence > 2:
        return "multi-sided";

      // social enterprise route
      case state.values.social > 1 &&
        state.values.beneficiariesValue === "End Customers":
        return "matchmaking";
      case state.values.social > 1 &&
        state.values.beneficiariesValue === "Means":
        return "multi-sided";
      case state.values.social > 1 && state.values.customisation <= 2:
        return "product";
      case state.values.social > 1 && state.values.customisation > 2:
        return "solutions";
      default:
        return "";
    }
  },
  getSocialFactor: (state) => {
    switch (state.values.social) {
      case 1:
        return "forProfit";
      case 2:
      case 3:
      case 4:
        return "socialEnterprise";
    }
  },

  // @TODO: Remove
  getIsComplete: () => {
    return false;
  },
};

export interface Icons {
  [index: string]: string;
}

export const sentenceValues = ["", "None", "Occasionally", "Often", "Fully"];

export type SlideResponse = {
  slide: string;
  value: string | string[] | number | boolean;
};

type BusinessModelFinderSlides = string[];
type BusinessModelFinderValues = {
  challenge: string; // @TODO: legacy, to be removed once unused in old views...
  hasStarted: boolean;
  social: number;
  sectorProfit: string;
  sectorSocialEnterprise: string;
  customisation: number;
  influence: number;
  problemDomain: string;
  beneficiaries: string;
  beneficiariesCustomers: string;
  beneficiariesValue: string;

  // add catch-all
  [slide: string]: string | string[] | number | boolean;
};

// Declare state
export type State = {
  slides: BusinessModelFinderSlides;
  values: BusinessModelFinderValues;
};

export const initialState = {
  slides: ["intro"],
  values: {
    challenge: "", // @TODO: legacy, to be removed once unused in old views...
    hasStarted: false,
    social: 0,
    sectorProfit: "",
    sectorSocialEnterprise: "",
    customisation: 0,
    influence: 0,
    problemDomain: "",
    beneficiaries: "",
    beneficiariesCustomers: "",
    beneficiariesValue: "",
  },
} as State;

// Create initial state
export const state = {
  slides: [...initialState.slides],
  values: { ...initialState.values },
};


import { defineComponent, computed, PropType } from "vue";
import { useStore } from "@/store";
import { Sector } from "@/store/sectors/state";
import { Icon } from "@/store/business-models/state";
import { CaseStudySummary } from "@/store/case-studies/state";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";

export default defineComponent({
  components: {
    Svg,
    Button,
  },
  props: {
    businessModel: {
      type: Object as PropType<Icon>,
      required: true,
    },
    sector: {
      type: Object as PropType<Sector>,
      required: true,
    },
    caseStudy: {
      type: Object as PropType<CaseStudySummary>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isAdded = computed(() =>
      store.getters["inspirationList/getIsCaseStudyListed"](props.caseStudy.id)
    );

    const handleAddToList = () => {
      if (isAdded.value) {
        store.commit("inspirationList/SET_SHOWN", true, {
          root: true,
        });
      } else {
        store.commit(
          "inspirationList/ADD_CASE_STUDY_INSPIRATION",
          props.caseStudy,
          {
            root: true,
          }
        );
      }
    };

    return {
      isAdded,
      handleAddToList,
    };
  },
});

import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_CHALLENGES]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_CHALLENGES]({ commit }) {
    commit(MutationTypes.SET_CHALLENGES, []);
    commit(MutationTypes.SET_LOADING, true);

    // query API with supplied options
    const challenges = [
      {
        challenge:
          "The pandemic means our customer numbers have halved in 6 months",
      },
      {
        challenge: "Making my business more robust for the future",
      },
      {
        challenge: "Transforming my business to be digital first",
      },
    ];
    setTimeout(() => {
      commit(MutationTypes.SET_CHALLENGES, challenges);
      commit(MutationTypes.SET_LOADING, false);
    }, 2000);
  },
};

import { createApp } from "vue";
import VueUniversalModal from "vue-universal-modal";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/sass/app.scss";

const app = createApp(App).use(store).use(router).use(VueUniversalModal, {
  teleportTarget: "#modals",
});
app.mount("#app");

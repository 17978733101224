
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { Params } from "@/store/case-studies/actions";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import BusinessModelTitle from "@/components/content/business-model-title/BusinessModelTitle.vue";
import BusinessModelDescription from "@/components/content/business-model-description/BusinessModelDescription.vue";
import CaseStudiesList from "@/components/content/case-studies/CaseStudiesList.vue";
import ListFilter from "@/components/content/list-filter/ListFilter.vue";

export default defineComponent({
  components: {
    PageContent,
    PageContentBlock,
    Editorial,
    BusinessModelTitle,
    BusinessModelDescription,
    CaseStudiesList,
    ListFilter,
  },
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    store.dispatch("businessModels/GET_BUSINESS_MODELS");

    const businessModel = computed(() =>
      store.getters["businessModels/getBusinessModelFromSlug"](props.slug)
    );

    onMounted(() => {
      if (!businessModel.value) {
        router.push({ name: "home" });
      }
    });

    // Case studies
    const caseStudiesIsLoading = computed(
      () => store.state.caseStudies.isLoading
    );
    const caseStudies = computed(
      () => store.state.caseStudies.caseStudySummaries
    );
    const socialFactor = computed(
      () => store.getters["businessModelFinder/getSocialFactor"]
    );

    const searchValue = ref("");
    const setSearchValue = (newSearchValue: string) => {
      searchValue.value = newSearchValue;
    };

    watch(businessModel, () => {
      searchValue.value = "";
    });

    // call on load, if the filter changes, or if the business model changes
    //   - the last as this doesn't trigger an onMounted event (as it reuses
    //   the same view)
    const retrieveCaseStudies = () => {
      if (!businessModel.value) return;
      const params: Params = {
        type: businessModel.value?.name,
      };
      if (socialFactor.value) {
        params.social_factor = socialFactor.value;
      }
      if (searchValue.value) {
        params.query = searchValue.value;
      }
      store.dispatch("caseStudies/GET_CASE_STUDIES", params);
    };
    onMounted(retrieveCaseStudies);
    watch(businessModel, retrieveCaseStudies);
    watch(searchValue, retrieveCaseStudies);

    return {
      businessModel,
      caseStudies,
      caseStudiesIsLoading,
      searchValue,
      setSearchValue,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "md:w-1/2 md:mx-6" }
const _hoisted_2 = { class: "mt-6 flex" }
const _hoisted_3 = { class: "w-4 h-4 mr-2" }
const _hoisted_4 = { class: "md:w-1/2 md:mx-6" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "relative md:w-2/3" }
const _hoisted_7 = { class: "responsive-iframe" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowCcwIcon = _resolveComponent("ArrowCcwIcon")!
  const _component_ButtonRouterLink = _resolveComponent("ButtonRouterLink")!
  const _component_Editorial = _resolveComponent("Editorial")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Svg = _resolveComponent("Svg")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["md:flex items-center justify-center px-6 pt-6 pb-12 text-white rounded-t-2xl", `bg-cebai-model-${_ctx.slug}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ButtonRouterLink, {
          to: { name: 'home' },
          type: "default"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ArrowCcwIcon, { class: "mr-1 fill-current" }),
            _createTextVNode(" Start again ")
          ]),
          _: 1
        }),
        _createVNode(_component_Editorial, null, {
          default: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.businessModel.name) + " Business Model", 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.businessModel.summary), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            class: "mr-2",
            type: "primary",
            onClick: _ctx.showModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Watch video "),
              _createVNode(_component_PlayIcon, { class: "inline ml-2 text-white group-hover:text-cebai-primary fill-current stroke-current transition duration-300" })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            type: _ctx.isAdded ? 'secondary' : 'outline-dark',
            onClick: _ctx.handleAddToList
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Svg, {
                  img: "inspiration-bulb.svg",
                  class: _normalizeClass(["fill-current transition duration-300", {
                'text-primary': _ctx.isAdded,
                'text-cebai-primary group-hover:text-white': !_ctx.isAdded,
              }])
                }, null, 8, ["class"])
              ]),
              _createTextVNode(" Add"),
              (_ctx.isAdded)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode("ed")
                  ], 64))
                : _createCommentVNode("", true),
              _createTextVNode(" to list ")
            ]),
            _: 1
          }, 8, ["type", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Svg, {
            img: `business-model-diagram_${_ctx.slug}_dark-bg.svg`
          }, null, 8, ["img"])
        ])
      ])
    ], 2),
    _createVNode(_component_Modal, {
      modelValue: _ctx.isShown,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShown) = $event)),
      close: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "hover:cursor-pointer px-2 py-2 absolute -right-8 -top-8 text-cebai-gray hover:text-white",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, [
            _createVNode(_component_XIcon, { class: "fill-current" })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("iframe", {
              width: "560",
              height: "315",
              src: _ctx.businessModel.video,
              frameborder: "0",
              allow: "autoplay; encrypted-media;",
              allowfullscreen: ""
            }, null, 8, _hoisted_8)
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "close"])
  ], 64))
}
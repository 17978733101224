import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-xs text-cebai-gray mb-2" }
const _hoisted_2 = { class: "uppercase text-cebai-primary font-medium" }
const _hoisted_3 = { class: "text-lg mb-1 break-words" }
const _hoisted_4 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex justify-start" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-small mb-4 break-words" }
const _hoisted_11 = { class: "flex justify-between" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "relative text-center" }
const _hoisted_15 = {
  key: 0,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BinIcon = _resolveComponent("BinIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-4", { 'border-t border-cebai-gray': !_ctx.first }])
  }, [
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.inspiration.type), 1),
      _createTextVNode(" – "),
      _createElementVNode("span", null, "Added " + _toDisplayString(_ctx.getRelativeDateString(_ctx.inspiration.added)), 1)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.inspiration.title), 1),
    (_ctx.inspiration.link)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _createElementVNode("a", {
            class: "break-all text-cebai-gray text-xs hover:text-cebai-primary italic",
            href: _ctx.inspiration.link,
            target: "_blank"
          }, _toDisplayString(_ctx.inspiration.link), 9, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _withDirectives(_createElementVNode("textarea", {
              ref: "inspirationTextarea",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editableNote) = $event)),
              class: "input w-full border my-2",
              placeholder: "Add note"
            }, null, 512), [
              [_vModelText, _ctx.editableNote]
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Button, {
                class: "mr-2",
                type: "primary",
                size: "xs",
                onClick: _ctx.handleSaveClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Save ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_Button, {
                size: "xs",
                onClick: _ctx.handleCancelClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Cancel ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.inspiration.note), 1),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Button, {
                type: "outline-gray",
                class: "mr-2",
                size: "xs",
                onClick: _ctx.startEditing
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_EditIcon, { class: "w-4 h-4 mr-2 fill-current" }),
                  (_ctx.inspiration.note)
                    ? (_openBlock(), _createElementBlock("em", _hoisted_12, "Edit note"))
                    : (_openBlock(), _createElementBlock("em", _hoisted_13, "Add note"))
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.confirmRemove)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_Button, {
                        size: "xs",
                        class: "mr-2",
                        onClick: _ctx.cancelRemoveInspiration
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Cancel ")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_Button, {
                        size: "xs",
                        type: "danger",
                        onClick: _ctx.confirmRemoveInspiration
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BinIcon, { class: "w-4 h-4 fill-current" })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
                  : (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      size: "xs",
                      type: "outline-gray",
                      onClick: _ctx.removeInspiration
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BinIcon, { class: "w-4 h-4 fill-current" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
              ])
            ])
          ]))
    ])
  ], 2))
}

import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const breadcrumb = computed(() => route.meta.breadcrumb);

    return {
      breadcrumb,
    };
  },
});

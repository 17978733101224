import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-xl mx-auto px-3 py-6" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "h-full mr-auto hidden lg:block" }
const _hoisted_4 = { class: "mt-6 lg:hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "mr-auto lg:mr-0 inline-block py-1 px-5 lg:px-10",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRedirectHome && _ctx.handleRedirectHome(...args)))
      }, [
        _createVNode(_component_Logo, { class: "fill-current" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Breadcrumb)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Breadcrumb)
    ])
  ]))
}
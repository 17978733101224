import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState, CaseStudyDetail } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { client as API } from "@/store/api-calls";
import { generateUrlParamsString } from "@/utils/text";
import { ApiCaseStudy, ApiCaseStudyWrapped } from "@/types/api-responses";

export type Params = {
  [key: string]: string;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_CASE_STUDIES](
    { commit, dispatch }: AugmentedActionContext,
    params: Params
  ): void;
  [ActionTypes.GET_CASE_STUDIES_FOR_INSIGHTS]({
    commit,
    dispatch,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_CASE_STUDY_DETAIL](
    { commit, dispatch }: AugmentedActionContext,
    id: string
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_CASE_STUDIES]({ commit }, params) {
    commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, []);
    commit(MutationTypes.SET_LOADING, true);

    const apiUrlParams = generateUrlParamsString(params);

    return API.getCaseStudies(apiUrlParams)
      .then((response) => {
        const caseStudies = response.data.map((item: ApiCaseStudy) => ({
          id: item.id,
          slug: item.slug,
          name: item.name,
          summary: item.summary,
          description: item.overview,
          sector: item.sector,
          sectorSlug: item.sector
            .toLowerCase()
            .replace(" ", "-")
            // fix mismatch between API "other industries" and "other services"
            .replace("industries", "services"),
          headquarters: item.headquarters,
          group: item.group,
          model: item.type,
          modelSlug: item.type.toLowerCase(),
          entities:
            item.entities?.map((entity: string) => ({
              id: entity,
              displayName: entity,
              internalName: entity,
            })) ?? [],
        }));
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, caseStudies);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, []);
      });
  },
  [ActionTypes.GET_CASE_STUDIES_FOR_INSIGHTS]({
    commit,
    dispatch,
    rootState,
    rootGetters,
  }) {
    commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, []);
    commit(MutationTypes.SET_LOADING, true);

    const {
      sectorProfit,
      challenge,
      customisation: customisationValue,
      influence: influenceValue,
    } = rootState.businessModelFinder.values;
    const businessModel = rootGetters["businessModelFinder/getBusinessModel"];

    return API.getCaseStudiesForInsights(
      challenge,
      sectorProfit,
      businessModel,
      customisationValue,
      influenceValue
    )
      .then((response) => {
        const { hits } = response.data;
        const caseStudies = hits.map((hit: ApiCaseStudyWrapped) => ({
          id: hit._source.id,
          slug: hit._source.slug,
          name: hit._source.name,
          summary: hit._source.summary,
          description: hit._source.overview,
          model: hit._source.type.toLowerCase(),
          entities: hit._source.entities.map((entity: string) => ({
            id: entity,
            displayName: entity,
            internalName: entity,
          })),
        }));
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, caseStudies);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_SUMMARIES, []);
      });
  },
  [ActionTypes.GET_CASE_STUDY_DETAIL]({ commit, dispatch }, id) {
    commit(MutationTypes.SET_CASE_STUDY_DETAIL, null);
    commit(MutationTypes.SET_LOADING, true);

    return API.getCaseStudy(id)
      .then((response) => {
        const { data } = response;
        const caseStudy: CaseStudyDetail = {
          id: data.id,
          slug: data.slug,
          name: data.name,
          summary: data.summary,
          description: data.overview,
          model: data.type,
          sector: data.sector,
          overview: data.overview,
          history: data.history,
          customers: data.customers,
          delivery: data.delivery,
          monetisation: data.monetization,
          sources: data.sources,
          disclaimer: data.disclaimer,
          published: data.published,
          engagement: data.engagement || undefined,
          problem: data.problem || undefined,
          beneficiaries: data.beneficiaries || undefined,
          socialImpact: data.social_impact || undefined,
          environmentalImpact: data.environmental_impact || undefined,
        };
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_DETAIL, caseStudy);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_DETAIL, null);
      });
  },
};

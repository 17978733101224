import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative rounded-2xl -mt-6 overflow-hidden", {
      'bg-gradient-to-b from-cebai-primary-gradient-start to-cebai-primary text-white':
        _ctx.variant === 'primary',
      'bg-cebai-primary-tint text-white': _ctx.variant === 'primary-tint',
      'bg-cebai-tertiary text-white': _ctx.variant === 'tertiary',
      'bg-white text-cebai-primary ': _ctx.variant === 'white',
      'bg-cebai-tint text-primary border-t border-cebai-gray':
        _ctx.variant === 'tint',
      'p-6 pb-12 md:p-12 pt-9 mb-0': _ctx.hasPadding === true,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_InspirationListToggle = _resolveComponent("InspirationListToggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_Content, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_Footer),
    _createVNode(_component_Alert),
    _createVNode(_component_InspirationListToggle)
  ], 64))
}
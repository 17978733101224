
import { defineComponent, computed, watch } from "vue";
import Toastify from "toastify-js";
import { useStore } from "@/store";

export default defineComponent({
  components: {},
  props: [],
  setup() {
    const store = useStore();

    const message = computed(() => store.getters["messages/getLatestMessage"]);

    watch(message, () => {
      if (message.value) {
        Toastify({
          text: message.value.message,
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "bottom",
          position: "left",
          stopOnFocus: true,
          className: message.value.type,
        }).showToast();
      }
    });

    return {
      message,
    };
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  props: {
    // The loading icon colour can be set either by setting the text colour of
    // the component, or by passing through this param
    color: {
      type: String,
      required: false,
      default: null,
    },
  },
});


import { defineComponent } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Button from "@/components/ui/button/Button.vue";
import ChevronRightIcon from "@/assets/images/icon-chevron-right.svg";

export default defineComponent({
  components: {
    Button,
    Editorial,
    ChevronRightIcon,
  },
  props: {},
  setup() {
    const store = useStore();
    const handleSubmit = () =>
      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "hasStarted",
        value: true,
      });

    return {
      handleSubmit,
    };
  },
});

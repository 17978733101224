
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Svg from "@/components/ui/svg/Svg.vue";

export default defineComponent({
  components: {
    Svg,
  },
  props: {},
  setup() {
    const store = useStore();

    const displayToggle = computed(() => !store.state.inspirationList.isShown);

    const handleShowInpirationList = () => {
      store.commit("inspirationList/SET_SHOWN", true);
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };

    return {
      handleShowInpirationList,
      displayToggle,
    };
  },
});

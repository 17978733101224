import { allSector, Sector } from "@/store/sectors/state";
import { CaseStudySummaries } from "@/store/case-studies/state";

// Declare state
export type State = {
  model: string;
  sector: Sector;
  group: string;
  headquarters: string;
  query: string;
  caseStudies: CaseStudySummaries;
};

// Create initial state
export const state = {
  model: "",
  sector: allSector,
  group: "",
  headquarters: "",
  query: "",
  caseStudies: [],
} as State;

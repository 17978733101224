import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, Message } from "./state";

// Getters types
export type Getters = {
  getLatestMessage(state: LocalState): void | Message;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getLatestMessage: (state) => {
    if (state.messages.length < 1) {
      return;
    }
    return state.messages[state.messages.length - 1];
  },
};


import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    Editorial,
  },
  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = target.value;

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "problemDomain",
        value,
      });
    };

    const values = computed(() => [
      "Civic Engagement",
      "Economic",
      "Environment",
      "Technology",
      "Education",
      "Food and water",
      "Health",
    ]);

    return {
      handleSubmit,
      values,
    };
  },
});

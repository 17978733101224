
import { defineComponent, onMounted, onBeforeMount } from "vue";
import { useStore } from "@/store";
import HomeTitle from "@/components/content/home-title/HomeTitle.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import BusinessModelFinder from "@/components/content/business-model-finder/BusinessModelFinder.vue";

export default defineComponent({
  components: {
    HomeTitle,
    PageContent,
    PageContentBlock,
    BusinessModelFinder,
  },
  setup() {
    const store = useStore();

    onBeforeMount(() => {
      // ensure BMF store is clear before mounting (always start afresh on home)
      store.commit("businessModelFinder/RESET_ALL");
    });

    onMounted(() => {
      // on initial load retrieve business model data (if not already retrieved)
      store.dispatch("businessModels/GET_BUSINESS_MODELS");
    });

    return {};
  },
});


import { defineComponent, computed, PropType, ref } from "vue";
import { useStore } from "@/store";
import { BusinessModel } from "@/store/business-models/state";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import ButtonRouterLink from "@/components/ui/button/ButtonRouterLink.vue";
import PlayIcon from "@/assets/images/icon-play.svg";
import XIcon from "@/assets/images/icon-x.svg";
import ArrowCcwIcon from "@/assets/images/icon-arrow-ccw.svg";

export default defineComponent({
  components: {
    Editorial,
    Svg,
    Button,
    ButtonRouterLink,
    PlayIcon,
    XIcon,
    ArrowCcwIcon,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    businessModel: {
      type: Object as PropType<BusinessModel>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isAdded = computed(() =>
      store.getters["inspirationList/getIsBusinessModelListed"](props.slug)
    );

    const handleAddToList = () => {
      if (isAdded.value) {
        store.commit("inspirationList/SET_SHOWN", true, {
          root: true,
        });
      } else {
        store.commit(
          "inspirationList/ADD_BUSINESS_MODEL_INSPIRATION",
          {
            slug: props.slug,
            name: `${props.businessModel.name} business model`,
          },
          {
            root: true,
          }
        );
      }
    };

    // modal
    const isShown = ref(false);
    const showModal = () => (isShown.value = true);
    const closeModal = () => (isShown.value = false);

    return {
      isAdded,
      handleAddToList,
      isShown,
      showModal,
      closeModal,
    };
  },
});

import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Resources } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_RESOURCES](state: S, resources: Resources): void;
  [MutationTypes.ADD_RESOURCES](state: S, resources: Resources): void;
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_RESOURCES](state: LocalState, resources: Resources) {
    state.resources = resources;
  },
  [MutationTypes.ADD_RESOURCES](state: LocalState, resources: Resources) {
    state.resources = [...state.resources, ...resources];
  },
  [MutationTypes.SET_LOADING](state: LocalState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_LABEL](
    { commit }: AugmentedActionContext,
    selectedLabel: string
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_LABEL]({ commit }, selectedLabel: string) {
    commit(MutationTypes.SET_LABEL, null);
    commit(MutationTypes.SET_LOADING, true);

    // query API with supplied options
    const label = {
      id: 10,
      name: selectedLabel,
      about: "This content is in development",
      links: [
        // {
        //   title: "Website",
        //   link: "#",
        // },
        // {
        //   title: "LinkedIn",
        //   link: "#",
        // },
        // {
        //   title: "YouTube",
        //   link: "#",
        // },
      ],
      insights: [
        // {
        //   title: "Lorem Ipsum",
        //   link: "#",
        // },
        // {
        //   title: "Dolor sit amet",
        //   link: "#",
        // },
      ],
    };
    setTimeout(() => {
      commit(MutationTypes.SET_LABEL, label);
      commit(MutationTypes.SET_LOADING, false);
    }, 2000);
  },
};


import { defineComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";

export default defineComponent({
  components: {
    Editorial,
    LoadingIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const businessModelSlug = computed(
      () => store.getters["businessModelFinder/getBusinessModel"]
    );

    onMounted(() => {
      if (businessModelSlug.value) {
        // add fake delay to slow transition to the BM page
        setTimeout(() => {
          router.push({
            name: "business-model",
            params: { slug: businessModelSlug.value },
          });
        }, 2000);
      }
    });

    return {};
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    variant: {
      type: String,
      required: true,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});

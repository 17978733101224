import { AxiosPromise } from "axios";
import { apiClient } from "@/utils/api";

// Action functions - case studies

const getCaseStudies = (params: string): AxiosPromise => {
  return apiClient.get(`/exemplars?${params}`);
};

const getCaseStudiesForInsights = (
  challenge: string,
  sector: string,
  businessModel: string,
  customisationValue: number,
  influenceValue: number
): AxiosPromise => {
  return apiClient.post(`/exemplars/business-model-finder`, {
    challenge,
    sector,
    businessModel,
    customisationValue,
    influenceValue,
  });
};

const getCaseStudy = (id: string): AxiosPromise => {
  return apiClient.get(`/exemplars/${id}`);
};

// Action functions - case study entities

const getCaseStudyEntitySparks = (params: string): AxiosPromise => {
  return apiClient.get(`/resources/byExemplar?${params}`);
};

// Action functions - resources

const getResources = (params: string): AxiosPromise => {
  return apiClient.get(`/resources/byBusinessModelType?${params}`);
};

const getResourcesForInsights = (params: string): AxiosPromise => {
  return apiClient.get(`/resources?${params}`);
};

const getResourcesForCaseStudy = (params: string): AxiosPromise => {
  return apiClient.get(`/resources/byExemplar?${params}`);
};

export const client = {
  getCaseStudies,
  getCaseStudiesForInsights,
  getCaseStudy,
  getCaseStudyEntitySparks,
  getResources,
  getResourcesForInsights,
  getResourcesForCaseStudy,
};

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

interface GetIsListed {
  (id: number | string): boolean;
}

// Getters types
export type Getters = {
  getIsLoading(state: LocalState): boolean;
  getIsEntityListed(state: LocalState): GetIsListed;
  getIsLabelListed(state: LocalState): GetIsListed;
  getIsCaseStudyListed(state: LocalState): GetIsListed;
  getIsResourceListed(state: LocalState): GetIsListed;
  getIsBusinessModelListed(state: LocalState): GetIsListed;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getIsEntityListed: (state) => (id) =>
    state.inspirations.some((inspiration) => inspiration.id === `entity-${id}`),
  getIsLabelListed: (state) => (id) =>
    state.inspirations.some((inspiration) => inspiration.id === `label-${id}`),
  getIsCaseStudyListed: (state) => (id) =>
    state.inspirations.some(
      (inspiration) => inspiration.id === `caseStudy-${id}`
    ),
  getIsResourceListed: (state) => (id) =>
    state.inspirations.some(
      (inspiration) => inspiration.id === `resource-${id}`
    ),
  getIsBusinessModelListed: (state) => (id) =>
    state.inspirations.some(
      (inspiration) => inspiration.id === `business-model-${id}`
    ),
};


import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import InspirationList from "@/components/content/inspiration-list/InspirationList.vue";

export default defineComponent({
  components: {
    InspirationList,
  },
  props: [],
  setup() {
    const store = useStore();
    const inspirationListIsShown = computed(
      () => store.state.inspirationList.isShown
    );
    return {
      inspirationListIsShown,
    };
  },
});

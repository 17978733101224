// Mutations enums
export enum MutationTypes {
  ADD_RESOURCE_INSPIRATION = "ADD_RESOURCE_INSPIRATION",
  ADD_CASE_STUDY_INSPIRATION = "ADD_CASE_STUDY_INSPIRATION",
  ADD_LABEL_INSPIRATION = "ADD_LABEL_INSPIRATION",
  ADD_ENTITY_INSPIRATION = "ADD_ENTITY_INSPIRATION",
  ADD_BUSINESS_MODEL_INSPIRATION = "ADD_BUSINESS_MODEL_INSPIRATION",
  UPDATE_INSPIRATION_NOTE = "UPDATE_INSPIRATION_NOTE",
  REMOVE_INSPIRATION = "REMOVE_INSPIRATION",
  SET_INSPIRATIONS = "SET_INSPIRATIONS",
  SET_SHOWN = "SET_SHOWN",
  SET_LOADING = "SET_LOADING",
}

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-white text-base border-t-2 border-cebai-secondary mt-8 pt-8" }
const _hoisted_2 = { class: "max-w-screen-xl mx-auto" }
const _hoisted_3 = { class: "md:flex items-start justify-between md:px-12 text-sm" }
const _hoisted_4 = { class: "flex mb-8" }
const _hoisted_5 = { class: "mr-12" }
const _hoisted_6 = { class: "text-cebai-gray" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: {
                name: 'terms-and-conditions',
              },
              class: "text-cebai-gray-light hover:text-cebai-gray"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Terms and Conditions ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: {
                name: 'cookies',
              },
              class: "text-cebai-gray-light hover:text-cebai-gray"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Cookies ")
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("p", _hoisted_6, "© " + _toDisplayString(_ctx.year) + " CebAI", 1)
      ])
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = { class: "w-4" }
const _hoisted_4 = {
  key: 1,
  class: "flex justify-between items-end mt-2"
}
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "p-2 rounded-full transition duration-300 group-hover:bg-cebai-tint" }
const _hoisted_8 = { class: "bg-cebai-tint flex p-2" }
const _hoisted_9 = { class: "w-4 h-4 mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_RefreshIcon = _resolveComponent("RefreshIcon")!
  const _component_Svg = _resolveComponent("Svg")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ThumbsUpIcon = _resolveComponent("ThumbsUpIcon")!
  const _component_ThumbsDownIcon = _resolveComponent("ThumbsDownIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, [
        _createTextVNode(" Explore this content related to "),
        _createElementVNode("strong", null, _toDisplayString(_ctx.entity.displayName), 1),
        _createTextVNode(": ")
      ]),
      (_ctx.sparkIsLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_LoadingIcon)
            ])
          ]))
        : (_ctx.randomSparks)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.randomSparks, (randomSpark, index) => {
                  return (_openBlock(), _createElementBlock("a", {
                    key: index,
                    class: "block mt-2 text-xs text-cebai-primary hover:text-cebai-gray font-medium",
                    target: "_blank",
                    href: randomSpark.url
                  }, _toDisplayString(randomSpark.title), 9, _hoisted_6))
                }), 128))
              ]),
              _createElementVNode("button", {
                class: "group focus:outline-none",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRefreshSpark && _ctx.handleRefreshSpark(...args)))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_RefreshIcon, { class: "fill-current" })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Button, {
        type: _ctx.isAdded ? 'secondary' : 'text',
        size: "xs",
        onClick: _cache[1] || (_cache[1] = () => _ctx.handleAddToList(_ctx.entity))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Svg, {
              img: "inspiration-bulb.svg",
              class: "text-black fill-current"
            })
          ]),
          _createTextVNode(" Add"),
          (_ctx.isAdded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("ed")
              ], 64))
            : _createCommentVNode("", true),
          _createTextVNode(" to list ")
        ]),
        _: 1
      }, 8, ["type"]),
      _createVNode(_component_Button, {
        type: "text",
        size: "xs",
        onClick: _cache[2] || (_cache[2] = () => _ctx.handleMoreLikeThis(_ctx.entity))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ThumbsUpIcon, { class: "w-4 h-4 mr-2 fill-current" }),
          _createTextVNode(" More like this ")
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        type: "text",
        size: "xs",
        onClick: _cache[3] || (_cache[3] = () => _ctx.handleLessLikeThis(_ctx.entity))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ThumbsDownIcon, { class: "w-4 h-4 mr-2 fill-current" }),
          _createTextVNode(" Less like this ")
        ]),
        _: 1
      })
    ])
  ], 64))
}
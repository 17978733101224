export type Resource = {
  title: string;
  action: string;
  category: string;
  url: string;
  label?: string;
  duration?: string;
  description?: string;
};

export type Resources = Array<Resource>;

export type Categories = Array<string>;

export const categories: Categories = [
  "All",
  "Leadership",
  "News",
  "Opinion",
  "Markets",
  "Strategy",
  "Academia",
];

// Declare state
export type State = {
  isLoading: boolean;
  resources: Resources;
};

// Create initial state
export const state = {
  isLoading: false,
  resources: [],
} as State;

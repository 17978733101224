
import { defineComponent, PropType, computed, ref } from "vue";
import { useStore } from "@/store";
import { CaseStudySummary } from "@/store/case-studies/state";
import CaseStudyEntities from "./CaseStudyEntities.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import ThumbsUpIcon from "@/assets/images/icon-thumbs-up.svg";
import ThumbsDownIcon from "@/assets/images/icon-thumbs-down.svg";
import MoreVerticalIcon from "@/assets/images/icon-more-vertical.svg";

export default defineComponent({
  components: {
    CaseStudyEntities,
    Svg,
    Button,
    ThumbsUpIcon,
    ThumbsDownIcon,
    MoreVerticalIcon,
  },
  props: {
    caseStudy: {
      type: Object as PropType<CaseStudySummary>,
      required: true,
    },
  },
  setup(props) {
    const dropdownIsShown = ref(false);
    const toggleDropdown = () =>
      (dropdownIsShown.value = !dropdownIsShown.value);
    const hideDropdown = () => (dropdownIsShown.value = false);

    const store = useStore();

    const isAdded = computed(() =>
      store.getters["inspirationList/getIsCaseStudyListed"](props.caseStudy.id)
    );

    const handleAddToList = () => {
      hideDropdown();

      if (isAdded.value) {
        store.commit("inspirationList/SET_SHOWN", true, {
          root: true,
        });
      } else {
        store.commit(
          "inspirationList/ADD_CASE_STUDY_INSPIRATION",
          props.caseStudy,
          {
            root: true,
          }
        );
      }
    };
    const handleMoreLikeThis = () => {
      hideDropdown();

      // eslint-disable-next-line no-console
      console.log("More like this", props.caseStudy.id);
    };
    const handleLessLikeThis = () => {
      hideDropdown();

      // eslint-disable-next-line no-console
      console.log("Less like this", props.caseStudy.id);
    };

    return {
      isAdded,
      handleAddToList,
      handleMoreLikeThis,
      handleLessLikeThis,
      dropdownIsShown,
      toggleDropdown,
      hideDropdown,
    };
  },
});

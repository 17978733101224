import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, Inspiration, Inspirations } from "./state";
import { Resource } from "@/store/resources/state";
import { CaseStudySummary, Entity } from "@/store/case-studies/state";
import { Label } from "@/store/labels/state";

export type InspirationNote = {
  id: string;
  note: string;
};

export type BusinessModel = {
  slug: string;
  name: string;
};

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.ADD_RESOURCE_INSPIRATION](state: S, resource: Resource): void;
  [MutationTypes.ADD_CASE_STUDY_INSPIRATION](
    state: S,
    caseStudy: CaseStudySummary
  ): void;
  [MutationTypes.ADD_LABEL_INSPIRATION](state: S, label: Label): void;
  [MutationTypes.ADD_ENTITY_INSPIRATION](state: S, entity: Entity): void;
  [MutationTypes.ADD_BUSINESS_MODEL_INSPIRATION](
    state: S,
    businessModel: BusinessModel
  ): void;
  [MutationTypes.REMOVE_INSPIRATION](state: S, id: string): void;
  [MutationTypes.UPDATE_INSPIRATION_NOTE](
    state: S,
    payload: InspirationNote
  ): void;
  [MutationTypes.SET_INSPIRATIONS](state: S, inspirations: Inspirations): void;
  [MutationTypes.SET_SHOWN](state: S, isShown: boolean): void;
  [MutationTypes.SET_LOADING](state: S, isLoading: boolean): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.ADD_RESOURCE_INSPIRATION](
    state: LocalState,
    resource: Resource
  ) {
    const inspiration: Inspiration = {
      id: `resource-${resource.title}`,
      title: resource.title,
      link: resource.url,
      note: "",
      type: "Resource",
      added: new Date().toISOString(),
    };
    state.inspirations.unshift(inspiration);
  },
  [MutationTypes.ADD_CASE_STUDY_INSPIRATION](
    state: LocalState,
    caseStudy: CaseStudySummary
  ) {
    const inspiration: Inspiration = {
      id: `caseStudy-${caseStudy.id}`,
      title: caseStudy.name,
      link: "",
      note: "",
      type: "Case Study",
      added: new Date().toISOString(),
    };
    state.inspirations.unshift(inspiration);
  },
  [MutationTypes.ADD_LABEL_INSPIRATION](state: LocalState, label: Label) {
    const inspiration: Inspiration = {
      id: `label-${label.id}`,
      title: label.name,
      link: "",
      note: "",
      type: "Label",
      added: new Date().toISOString(),
    };
    state.inspirations.unshift(inspiration);
  },
  [MutationTypes.ADD_ENTITY_INSPIRATION](state: LocalState, entity: Entity) {
    const inspiration: Inspiration = {
      id: `entity-${entity.id}`,
      title: entity.displayName,
      link: "",
      note: "",
      type: "Entity",
      added: new Date().toISOString(),
    };
    state.inspirations.unshift(inspiration);
  },
  [MutationTypes.ADD_BUSINESS_MODEL_INSPIRATION](state, businessModel) {
    const inspiration: Inspiration = {
      id: `business-model-${businessModel.slug}`,
      title: businessModel.name,
      link: "",
      note: "",
      type: "Business Model",
      added: new Date().toISOString(),
    };
    state.inspirations.unshift(inspiration);
  },
  [MutationTypes.REMOVE_INSPIRATION](state: LocalState, id: string) {
    const index = state.inspirations.findIndex(
      (inspiration) => inspiration.id === id
    );
    state.inspirations.splice(index, 1);
  },
  [MutationTypes.UPDATE_INSPIRATION_NOTE](
    state: LocalState,
    { id, note }: InspirationNote
  ) {
    const index = state.inspirations.findIndex(
      (inspiration) => inspiration.id === id
    );
    state.inspirations[index].note = note;
  },
  [MutationTypes.SET_INSPIRATIONS](
    state: LocalState,
    inspirations: Inspirations
  ) {
    state.inspirations = inspirations;
  },
  [MutationTypes.SET_SHOWN](state: LocalState, isShown: boolean) {
    state.isShown = isShown;
  },
  [MutationTypes.SET_LOADING](state: LocalState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { client as API } from "@/store/api-calls";
import { generateUrlParamsString } from "@/utils/text";

type CaseStudyEntity = {
  caseStudy: string;
  entity: string;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_CASE_STUDY_ENTITY_SPARKS](
    { commit }: AugmentedActionContext,
    payload: CaseStudyEntity
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_CASE_STUDY_ENTITY_SPARKS](
    { commit, dispatch },
    { caseStudy, entity }
  ) {
    commit(MutationTypes.SET_LOADING, true);
    commit(MutationTypes.SET_CASE_STUDY_ENTITY_SPARKS, []);

    const apiUrlParams = generateUrlParamsString({
      entity,
      exemplarName: caseStudy,
    });

    return API.getCaseStudyEntitySparks(apiUrlParams)
      .then((response) => {
        const { data } = response;

        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_ENTITY_SPARKS, data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_CASE_STUDY_ENTITY_SPARKS, []);
      });
  },
};

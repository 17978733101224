
import { defineComponent, ref, computed, onMounted, PropType } from "vue";
import { useStore } from "@/store";
import { allSector, Sector } from "@/store/sectors/state";
import { BusinessModel } from "@/store/business-models/state";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import Button from "@/components/ui/button/Button.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import SearchIcon from "@/assets/images/icon-search.svg";
import RefreshIcon from "@/assets/images/icon-refresh.svg";
import XIcon from "@/assets/images/icon-x.svg";

export default defineComponent({
  components: {
    Button,
    Svg,
    LoadingIcon,
    SearchIcon,
    RefreshIcon,
    XIcon,
  },
  props: {
    selectedSector: {
      type: Object as PropType<Sector>,
      default: allSector,
    },
    setSelectedSector: {
      type: Function,
      default: () => ({}),
    },
    selectedTheme: {
      type: String,
      default: "",
    },
    setSelectedTheme: {
      type: Function,
      default: () => ({}),
    },
    setSearch: {
      type: Function,
      default: () => ({}),
    },
    showSectors: {
      type: Boolean,
      default: true,
    },
    showThemes: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    businessModel: {
      type: Object as PropType<BusinessModel>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    // sectors
    const sectors = computed(() => store.getters["sectors/getSectorsWithAll"]);

    // themes
    const themes = ref<string[]>();
    const themesAreLoading = ref(false);
    const handleRefreshThemes = () => selectRandomThemes();

    const selectRandomThemes = () => {
      themesAreLoading.value = true;
      setTimeout(() => {
        themesAreLoading.value = false;
        if (!props.businessModel) return;

        let newThemes = [...props.businessModel.themes];

        // reduce...
        while (newThemes.length > 5) {
          const index = Math.floor(Math.random() * newThemes.length);
          newThemes.splice(index, 1);
        }

        // ...and shuffle
        newThemes.sort(() => (Math.random() > 0.5 ? 1 : -1));
        newThemes = ["All", ...newThemes];

        themes.value = newThemes;
      }, 1000);
    };

    onMounted(() => {
      selectRandomThemes();
    });

    const search = ref("");

    const submitIsDisabled = computed(() => {
      return search.value.length === 0;
    });

    const resetSearch = () => {
      search.value = "";
      props.setSearch(search.value);
    };

    return {
      sectors,
      handleRefreshThemes,
      themesAreLoading,
      themes,
      search,
      resetSearch,
      submitIsDisabled,
    };
  },
});


import { defineComponent, computed, ref, watch, onMounted, provide } from "vue";
import { TinySliderInstance } from "tiny-slider";
import Multiselect from "@vueform/multiselect";
import { useStore } from "@/store";
import Tippy from "@/components/ui/tippy/Tippy.vue";
import { TinySliderEl } from "@/components/ui/tiny-slider";
import TinySlider from "@/components/ui/tiny-slider/TinySlider.vue";
import PageTitle from "@/components/ui/page-title/PageTitle.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import SearchIcon from "@/assets/images/icon-search.svg";

import PlaceholderSvgImage from "@/assets/images/_placeholder.svg";

export default defineComponent({
  components: {
    PageTitle,
    Editorial,
    Button,
    Svg,
    Markdown,
    PageContent,
    PageContentBlock,
    Tabs,
    Tab,
    TabContent,
    Multiselect,
    LoadingIcon,
    Tippy,
    TinySlider,
    SearchIcon,
    PlaceholderSvgImage,
  },
  setup() {
    const store = useStore();

    // alerts
    const addInfo = () =>
      store.dispatch("messages/ADD_INFO_MESSAGE", "Info message", {
        root: true,
      });
    const addSuccess = () =>
      store.dispatch("messages/ADD_SUCCESS_MESSAGE", "Success message", {
        root: true,
      });
    const addError = () =>
      store.dispatch("messages/ADD_ERROR_MESSAGE", "Error message", {
        root: true,
      });

    // markdown
    const markdownContent = `
## h2 title

- unordered
- list

a paragraph of text

### h3 title

some text with __bold__ and *italic*`;

    // svgs
    const svgPath = "_placeholder.svg";

    // tabs
    const tabs = {
      "tab-one": "Tab one content",
      "tab-two": "Tab two content",
      "tab-three": "Tab three content",
      "tab-four": "Tab four content",
    };
    const activeTab = computed(() => Object.keys(tabs)[0]);

    // forms
    const inputTextValue = ref("");
    const searchTextValue = ref("");
    const selectOptions = computed(() => ["lorem", "ipsum"]);
    const selectValue = ref(selectOptions.value[0]);
    const multiselectOptions = computed(() => ["lorem", "ipsum"]);
    const multiselectValue = ref([]);
    const multiselectTagOptions = computed(() => ["lorem", "ipsum"]);
    const multiselectTagValues = ref([]);
    const multiselectClasses = {
      search:
        "w-full absolute inset-0 outline-none appearance-none outline-none box-border border-0 text-base font-sans bg-white rounded pt-2 pl-3.5",
      tagsSearch:
        "absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full",
      containerActive: "ring ring-offset-cebai-accent ring-opacity-30",
      optionSelected: "text-white bg-cebai-accent",
      optionSelectedPointed: "text-white bg-cebai-accent",
      tag: "bg-cebai-accent text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap",
    };

    // loading
    const isLoading = ref(true);
    const toggleLoadingState = () => (isLoading.value = !isLoading.value);

    // tooltips
    const tippyOptions = {
      interactive: true,
      hideOnClick: true,
      appendTo: document.body,
      maxWidth: 380,
      onShow() {
        // console.log("tooltip shown");
      },
      onHide() {
        // console.log("tooltip hidden");
      },
    };

    // slider
    const tinySliderOptions = {
      autoHeight: true,
      mouseDrag: false,
      touch: false,
      autoplay: false,
      loop: false,
    };

    // store a reference to the slider HTML element
    const exampleSlider = ref<TinySliderEl>();
    provide("bind[exampleSlider]", (el: TinySliderEl) => {
      exampleSlider.value = el;
    });

    // use slider HTML element reference
    let slider: TinySliderInstance;

    // when the active slide changes, update the slider
    const slide = ref(0);
    watch(slide, () => {
      slider.goTo(slide.value);
    });

    onMounted(() => {
      if (exampleSlider.value) {
        // on initial load, update the slider
        const sliderEl: TinySliderEl = exampleSlider.value;
        slider = sliderEl.tns;
        slider.goTo(slide.value);
      }
    });

    // provide method to allow individual slides to tell the slider to update
    const updateSliderDimensions = () => {
      slider.refresh();
    };

    const goToSlide = (num: number) => (slide.value = num);

    return {
      // alerts
      addInfo,
      addSuccess,
      addError,

      // markdown
      markdownContent,

      // svgs
      svgPath,

      // tabs
      tabs,
      activeTab,

      // forms
      inputTextValue,
      searchTextValue,
      selectOptions,
      selectValue,
      multiselectOptions,
      multiselectValue,
      multiselectTagOptions,
      multiselectTagValues,
      multiselectClasses,

      // loading
      isLoading,
      toggleLoadingState,

      // tooltips
      tippyOptions,

      // slides
      tinySliderOptions,
      updateSliderDimensions,
      goToSlide,
      slide,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-12 h-12 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Svg = _resolveComponent("Svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed top-1/2 -translate-y-1/2 transition z-40", {
      'translate-x-3 hover:translate-x-1 right-0': _ctx.displayToggle,
      'translate-x-full right-0': !_ctx.displayToggle,
    }])
  }, [
    _createElementVNode("button", {
      class: "block group p-2 rounded-l-full border-2 border-white bg-cebai-secondary hover:bg-cebai-secondary-tint shadow-lg transition duration-300",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowInpirationList && _ctx.handleShowInpirationList(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Svg, {
          img: "inspiration-bulb.svg",
          class: "text-cebai-primary fill-current"
        })
      ])
    ])
  ], 2))
}

import { defineComponent } from "vue";
import PageTitle from "@/components/ui/page-title/PageTitle.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import ButtonRouterLink from "@/components/ui/button/ButtonRouterLink.vue";

export default defineComponent({
  components: {
    PageTitle,
    Editorial,
    PageContent,
    PageContentBlock,
    ButtonRouterLink,
  },
  setup() {
    return {};
  },
});


import { defineComponent, ref, PropType, onUpdated } from "vue";
import formatRelative from "date-fns/formatRelative";
import { useStore } from "@/store";
import { Inspiration } from "@/store/inspiration-list/state";
import Button from "@/components/ui/button/Button.vue";
import BinIcon from "@/assets/images/icon-bin.svg";
import EditIcon from "@/assets/images/icon-edit.svg";

export default defineComponent({
  components: {
    Button,
    BinIcon,
    EditIcon,
  },
  props: {
    inspiration: {
      type: Object as PropType<Inspiration>,
      required: true,
    },
    first: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    // show/hide editable form
    const isEditing = ref(false);
    const startEditing = () => (isEditing.value = true);
    const endEditing = () => (isEditing.value = false);

    // handle save/cancel
    const editableNote = ref(props.inspiration.note);

    const handleSaveClick = () => {
      store.dispatch("inspirationList/UPDATE_INSPIRATION_NOTE", {
        id: props.inspiration.id,
        note: editableNote.value,
      });
      endEditing();
    };
    const handleCancelClick = () => {
      editableNote.value = props.inspiration.note;
      endEditing();
    };

    const confirmRemove = ref(false);

    // handle delete
    const removeInspiration = () => {
      confirmRemove.value = true;
    };
    const cancelRemoveInspiration = () => {
      confirmRemove.value = false;
    };
    const confirmRemoveInspiration = () => {
      store.commit("inspirationList/REMOVE_INSPIRATION", props.inspiration.id);
    };

    // calculate date
    const getRelativeDateString = (dateString: string) =>
      formatRelative(new Date(dateString), new Date());

    // focus text area when displayed
    const inspirationTextarea = ref<HTMLElement>();
    onUpdated(() => {
      inspirationTextarea.value?.focus();
    });

    return {
      getRelativeDateString,
      confirmRemove,
      isEditing,
      editableNote,
      startEditing,
      removeInspiration,
      cancelRemoveInspiration,
      confirmRemoveInspiration,
      endEditing,
      handleCancelClick,
      handleSaveClick,
      inspirationTextarea,
    };
  },
});

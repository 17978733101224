
import { defineComponent, computed, watch } from "vue";
import { useStore } from "@/store";
import cloneDeep from "lodash.clonedeep";
import Button from "@/components/ui/button/Button.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import InspirationCard from "./InspirationCard.vue";
import DownloadIcon from "@/assets/images/icon-download.svg";
import XIcon from "@/assets/images/icon-x.svg";

export default defineComponent({
  components: {
    LoadingIcon,
    Svg,
    Button,
    InspirationCard,
    DownloadIcon,
    XIcon,
  },
  setup() {
    const store = useStore();

    const isLoading = computed(() => store.state.inspirationList.isLoading);
    const isShown = computed(() => store.state.inspirationList.isShown);
    const inspirations = computed(
      () => store.state.inspirationList.inspirations
    );

    const hidePanel = () => {
      store.commit("inspirationList/SET_SHOWN", false);
    };

    const handleDownloadList = () => {
      store.dispatch("messages/ADD_INFO_MESSAGE", "Download started...", {
        root: true,
      });
    };

    watch(isShown, () => {
      if (isShown.value) {
        // store.dispatch("inspirationList/GET_INSPIRATIONS");
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }
    });

    watch(
      () => cloneDeep(inspirations.value),
      (newVal, oldVal) => {
        store.commit("inspirationList/SET_SHOWN", true);

        // only if adding a new inspiration, scroll to top
        if (newVal > oldVal) {
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    );

    return {
      isLoading,
      isShown,
      hidePanel,
      inspirations,
      handleDownloadList,
    };
  },
});

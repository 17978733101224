export interface Sector {
  name: string;
  slug: string;
  apiSlug: string;
  image: string;
  imageDark: string;
}
export interface Sectors {
  [slug: string]: Sector;
}

export const allSector: Sector = {
  name: "All",
  slug: "all",
  apiSlug: "",
  image: "sector_all.svg",
  imageDark: "sector_all-dark.svg",
};

export const sectors: Sectors = {
  "digital-content": {
    name: "Digital Media Industry",
    apiSlug: "Digital content",
    slug: "digital-content",
    image: "sector_digital-content.svg",
    imageDark: "sector_digital-content-dark.svg",
  },
  finance: {
    name: "Finance Business Sector",
    apiSlug: "Finance",
    slug: "finance",
    image: "sector_finance.svg",
    imageDark: "sector_finance-dark.svg",
  },
  hospitality: {
    name: "Hospitality Industry",
    apiSlug: "Hospitality",
    slug: "hospitality",
    image: "sector_hospitality.svg",
    imageDark: "sector_hospitality-dark.svg",
  },
  "other-services": {
    name: "Service Industries",
    apiSlug: "Other services",
    slug: "other-services",
    image: "sector_other-services.svg",
    imageDark: "sector_other-services-dark.svg",
  },
  "physical-products": {
    name: "Physical Goods Sector",
    apiSlug: "Physical products",
    slug: "physical-products",
    image: "sector_physical-products.svg",
    imageDark: "sector_physical-products-dark.svg",
  },
};

// Declare state
export type State = Record<string, never>; // {};

// Create initial state
export const state = {} as State;

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(["button", {
      [`button--type-${_ctx.type}`]: _ctx.type,
      [`button--size-${_ctx.size}`]: _ctx.size,
      [`button--rounded-${_ctx.rounded}`]: _ctx.rounded,
      [`button--disabled`]: _ctx.disabled,
    }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
export type Entity = {
  id: number;
  displayName: string;
  internalName: string;
};

export type CaseStudySummary = {
  id: number;
  name: string;
  slug?: string;
  summary: string;
  description: string;
  sector?: string;
  sectorSlug?: string;
  model?: string;
  modelSlug?: string;
  headquarters?: string;
  group?: string;
  entities: Array<Entity>;
};

export type CaseStudyDetail = {
  id: number;
  slug: string;
  name: string;
  summary: string;
  description: string;
  model: string;
  sector: string;
  overview: string;
  history: string;
  customers: string;
  delivery: string;
  monetisation: string;
  sources: string;
  disclaimer: string;
  published: string;
  engagement?: string;
  problem?: string;
  beneficiaries?: string;
  socialImpact?: string;
  environmentalImpact?: string;
};

export type CaseStudySummaries = Array<CaseStudySummary>;

// Declare state
export type State = {
  isLoading: boolean;
  caseStudySummaries: CaseStudySummaries;
  caseStudyDetail: CaseStudyDetail | null;
};

// Create initial state
export const state = {
  isLoading: false,
  caseStudySummaries: [],
  caseStudyDetail: null,
} as State;

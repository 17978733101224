
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import Breadcrumb from "@/components/layout/breadcrumb/Breadcrumb.vue";
import Logo from "@/assets/images/logo.svg";

export default defineComponent({
  components: {
    Breadcrumb,
    Logo,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const handleRedirectHome = async () => {
      await router.push({ path: "/" });

      // ensure scroll to top of page
      if (route.meta?.scrollPos) {
        route.meta.scrollPos.top = 0;
      }
    };

    return {
      handleRedirectHome,
    };
  },
});

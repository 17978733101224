export type Inspiration = {
  id: string;
  title: string;
  link: string;
  note: string;
  type: string;
  added: string;
};

export type Inspirations = Array<Inspiration>;

// Declare state
export type State = {
  isShown: boolean;
  isLoading: boolean;
  inspirations: Inspirations;
};

// Create initial state
export const state = {
  isShown: false,
  isLoading: false,
  inspirations: [],
} as State;

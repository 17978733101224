import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-xl mx-auto xl:relative xl:z-10" }
const _hoisted_2 = { class: "mx-5 md:mx-10 rounded-2xl bg-cebai-tint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InspirationList = _resolveComponent("InspirationList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InspirationList),
    _createElementVNode("div", {
      class: _normalizeClass(["transition-all mr-0 relative", { 'xl:mr-96': _ctx.inspirationListIsShown }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ]))
}
import {
  tns,
  TinySliderSettings,
  TinySliderInstance,
} from "tiny-slider/src/tiny-slider";

export type TinySliderEl = HTMLElement & {
  tns: TinySliderInstance;
};

interface Props {
  options: TinySliderSettings;
}

const init = (el: TinySliderEl, props: TinySliderSettings & Props): void => {
  el.tns = tns({
    container: el,
    slideBy: "page",
    mouseDrag: true,
    autoplay: true,
    controls: false,
    nav: false,
    speed: 500,
    ...props.options,
  });
};

const reInit = (el: TinySliderEl): void => {
  el.tns.destroy();
  el.tns.rebuild();
};

export { init, reInit };


import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import Button from "@/components/ui/button/Button.vue";
import PageTitle from "@/components/ui/page-title/PageTitle.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import CaseStudyHeader from "@/components/content/case-study-header/CaseStudyHeader.vue";
import CaseStudiesList from "@/components/content/case-studies/CaseStudiesList.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import PlusIcon from "@/assets/images/icon-plus.svg";
import MinusIcon from "@/assets/images/icon-minus.svg";

export default defineComponent({
  components: {
    Button,
    PageTitle,
    Editorial,
    Markdown,
    PageContent,
    PageContentBlock,
    CaseStudyHeader,
    CaseStudiesList,
    LoadingIcon,
    PlusIcon,
    MinusIcon,
  },
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const hasBack = computed(() => router.options.history.state.back);
    const handleGoBack = () => {
      router.go(-1);
    };

    store.dispatch("businessModels/GET_BUSINESS_MODELS");

    const sourcesExpanded = ref(false);
    const handleToggleSources = () => {
      sourcesExpanded.value = !sourcesExpanded.value;
    };

    // caseStudy detail
    const caseStudyIsLoading = computed(
      () => store.getters["caseStudies/getIsLoading"]
    );
    const caseStudy = computed(
      () => store.getters["caseStudies/getCaseStudyDetail"]
    );
    const requestCaseStudyDetail = () => {
      store.dispatch("caseStudies/GET_CASE_STUDY_DETAIL", props.slug);
    };
    onMounted(requestCaseStudyDetail);
    watch(() => props.slug, requestCaseStudyDetail);

    // related caseStudies
    watch(caseStudy, () => {
      if (!caseStudy.value && !caseStudyIsLoading.value) {
        router.push({ name: "home" });
      }
      if (!caseStudyIsLoading.value) {
        store.dispatch("caseStudies/GET_CASE_STUDIES", {
          sector: caseStudy?.value?.sector,
        });
      }
    });
    const caseStudies = computed(() =>
      store.getters["caseStudies/getCaseStudySummaries"](6)
    );

    // sectors
    const caseStudySectorName = computed(
      () => caseStudy?.value?.sector.toLowerCase() || ""
    );
    const sector = computed(() =>
      store.getters["sectors/getSectorFromName"](
        caseStudySectorName.value || ""
      )
    );

    // business model icon
    const businessModelIcon = computed(() =>
      store.getters["businessModels/getBusinessModelIconFromName"](
        caseStudy?.value?.model || ""
      )
    );

    return {
      hasBack,
      handleGoBack,
      sourcesExpanded,
      handleToggleSources,
      caseStudyIsLoading,
      caseStudy,
      caseStudies,
      sector,
      businessModelIcon,
    };
  },
});

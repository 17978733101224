import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, thinking } from "./state";

type Item = {
  title: string;
  text: string;
};

interface GetItems {
  (slug: string): Item[];
}

// Getters types
export type Getters = {
  getThinkingFromSlug(): GetItems;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getThinkingFromSlug: () => (slug: string) => {
    return thinking.map((item) => ({
      title: item.title,
      text: item.text[slug],
    }));
  },
};

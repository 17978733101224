
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Svg from "@/components/ui/svg/Svg.vue";

export default defineComponent({
  components: {
    Editorial,
    Svg,
  },
  setup() {
    const store = useStore();

    const handleSubmit = (e: Event) => {
      const target = e.currentTarget as HTMLInputElement;
      const value = target.value;

      store.dispatch("businessModelFinder/SET_VALUE", {
        slide: "sectorProfit",
        value,
      });
    };

    const sectors = computed(() => store.getters["sectors/getSectors"]);

    return {
      handleSubmit,
      sectors,
    };
  },
});

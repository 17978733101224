import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "md:flex justify-between items-center mx-6" }
const _hoisted_2 = { class: "hidden md:block md:invisible" }
const _hoisted_3 = { class: "w-4 h-4 ml-2" }
const _hoisted_4 = { class: "md:flex items-center px-6 rounded-md md:rounded-full bg-white border border--cebai-gray" }
const _hoisted_5 = { class: "flex items-center my-1 mr-8" }
const _hoisted_6 = { class: "flex items-center w-8 h-8 mr-2" }
const _hoisted_7 = { class: "text-base font-medium" }
const _hoisted_8 = { class: "flex items-center my-1" }
const _hoisted_9 = { class: "flex items-center w-8 h-8 mr-2" }
const _hoisted_10 = { class: "text-base font-medium" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "w-4 h-4 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Svg = _resolveComponent("Svg")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, null, {
        default: _withCtx(() => [
          _createTextVNode(" Add"),
          (_ctx.isAdded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("ed")
              ], 64))
            : _createCommentVNode("", true),
          _createTextVNode(" to list "),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Svg, { img: "inspiration-bulb.svg" })
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Svg, {
            img: _ctx.sector.image
          }, null, 8, ["img"])
        ]),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.sector.name), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Svg, {
            class: _normalizeClass(`fill-current text-cebai-model-${_ctx.businessModel.slug}`),
            img: _ctx.businessModel.image
          }, null, 8, ["class", "img"])
        ]),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.businessModel.name) + " Business Model ", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Button, {
        type: _ctx.isAdded ? 'outline-dark' : 'primary',
        class: "group",
        onClick: _ctx.handleAddToList
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add"),
          (_ctx.isAdded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("ed")
              ], 64))
            : _createCommentVNode("", true),
          _createTextVNode(" to list "),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_Svg, {
              img: "inspiration-bulb.svg",
              class: _normalizeClass(["fill-current transition duration-300", {
              'text-white group-hover:text-cebai-primary': !_ctx.isAdded,
              'text-cebai-primary group-hover:text-white': _ctx.isAdded,
            }])
            }, null, 8, ["class"])
          ])
        ]),
        _: 1
      }, 8, ["type", "onClick"])
    ])
  ]))
}

import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    size: {
      type: String,
      required: false,
      default: "default",
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    rounded: {
      type: String,
      required: false,
      default: "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});


import { defineComponent, PropType, computed, ref, watch } from "vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import { useStore } from "@/store";
import { CaseStudySummary, Entity } from "@/store/case-studies/state";
import { Spark } from "@/store/case-study-entities/state";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import RefreshIcon from "@/assets/images/icon-refresh.svg";
import ThumbsUpIcon from "@/assets/images/icon-thumbs-up.svg";
import ThumbsDownIcon from "@/assets/images/icon-thumbs-down.svg";

export default defineComponent({
  components: {
    LoadingIcon,
    Svg,
    Button,
    RefreshIcon,
    ThumbsUpIcon,
    ThumbsDownIcon,
  },
  props: {
    caseStudy: {
      type: Object as PropType<CaseStudySummary>,
      required: true,
    },
    entity: {
      type: Object as PropType<Entity>,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const isAdded = computed(() =>
      store.getters["inspirationList/getIsEntityListed"](props.entity.id)
    );

    const handleAddToList = (entity: Entity) => {
      if (isAdded.value) {
        store.commit("inspirationList/SET_SHOWN", true, {
          root: true,
        });
      } else {
        store.commit("inspirationList/ADD_ENTITY_INSPIRATION", entity, {
          root: true,
        });
      }
    };
    const handleMoreLikeThis = (entity: Entity) => {
      // eslint-disable-next-line no-console
      console.log("More like this", entity.id);
    };
    const handleLessLikeThis = (entity: Entity) => {
      // eslint-disable-next-line no-console
      console.log("Less like this", entity.id);
    };

    const sparkIsLoading = ref(true);

    watch(
      () => props.isVisible,
      () => {
        if (props.isVisible) {
          store.dispatch("caseStudyEntities/GET_CASE_STUDY_ENTITY_SPARKS", {
            caseStudy: props.caseStudy.name,
            entity: props.entity.displayName,
          });
        }
      }
    );

    const randomSparks = ref<Spark[]>();

    const handleRefreshSpark = () => {
      sparkIsLoading.value = true;
      setTimeout(() => {
        randomSparks.value = store.getters["caseStudyEntities/getRandomSparks"];
        sparkIsLoading.value = false;
      }, 1000);
    };

    const sparks = computed(() => store.state.caseStudyEntities.sparks);
    watch(
      () => sparks.value,
      () => {
        if (props.isVisible && sparks.value.length > 0) {
          handleRefreshSpark();
        }
      }
    );

    return {
      isAdded,
      randomSparks,
      sparkIsLoading,
      handleAddToList,
      handleMoreLikeThis,
      handleLessLikeThis,
      handleRefreshSpark,
    };
  },
});


import { defineComponent } from "vue";
import XIcon from "@/assets/images/icon-x.svg";

export default defineComponent({
  components: {
    XIcon,
  },
  props: {
    overlayIsShown: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Function,
      default: () => ({}),
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeTitle = _resolveComponent("HomeTitle")!
  const _component_BusinessModelFinder = _resolveComponent("BusinessModelFinder")!
  const _component_PageContentBlock = _resolveComponent("PageContentBlock")!
  const _component_PageContent = _resolveComponent("PageContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeTitle),
    _createVNode(_component_PageContent, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageContentBlock, {
          "has-padding": false,
          variant: "primary-tint"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BusinessModelFinder)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "inline-block mx-auto overflow-auto align-bottom h-full p-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 right-0 top-0 bottom-0 z-10 bg-black bg-opacity-50 transition-all duration-300 backdrop-filter backdrop-blur", {
      'visible opacity-100': _ctx.overlayIsShown,
      'invisible opacity-0': !_ctx.overlayIsShown,
    }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideOverlay && _ctx.hideOverlay(...args)))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-4 right-4 top-1/2 md:left-10 md:right-10 -translate-y-1/2 z-20 bg-white rounded-2xl overflow-hidden text-black transition-all duration-300", {
      'visible opacity-100': _ctx.overlayIsShown,
      'invisible opacity-0': !_ctx.overlayIsShown,
    }])
    }, [
      _createElementVNode("button", {
        class: "absolute right-0 top-0 p-2",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideOverlay && _ctx.hideOverlay(...args)))
      }, [
        _createVNode(_component_XIcon, { class: "m-1 fill-current" })
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 2)
  ], 64))
}
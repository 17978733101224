import { ActionTree, CommitOptions, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { client as API } from "@/store/api-calls";
import { generateUrlParamsString } from "@/utils/text";
import { ApiResource } from "@/types/api-responses";

type Params = {
  [key: string]: string;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_RESOURCES](
    { commit, dispatch }: AugmentedActionContext,
    params: Params
  ): void;
  [ActionTypes.GET_RESOURCES_FOR_INSIGHTS]({
    commit,
  }: AugmentedActionContext): void;
  [ActionTypes.GET_RESOURCES_FOR_CASE_STUDY](
    { commit, dispatch }: AugmentedActionContext,
    params: Params
  ): void;
}

const mapResourceDataToResource = (resource: ApiResource) => ({
  title: resource.title,
  action: resource.action,
  url: resource.url,
  category: resource.category,
  label: resource.label || null,
  description: resource.description || null,
  duration: resource.duration ? calculateDuration(resource.duration) : null,
});

const calculateDuration = (duration: string): string => {
  const durationArray = duration.split(":");
  if (durationArray.length === 2) {
    return `${durationArray[0]} mins`;
  }
  if (durationArray.length === 3) {
    return `${
      parseInt(durationArray[0], 10) * 60 + parseInt(durationArray[1], 10)
    }  mins`;
  }
  return "";
};

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_RESOURCES]({ commit, dispatch }, params) {
    commit(MutationTypes.SET_RESOURCES, []);
    commit(MutationTypes.SET_LOADING, true);

    const apiUrlParams = generateUrlParamsString(params);

    return API.getResources(apiUrlParams)
      .then((response) => {
        const resources = response.data.map(mapResourceDataToResource);
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, resources);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, []);
      });
  },
  [ActionTypes.GET_RESOURCES_FOR_INSIGHTS]({ commit, rootState, dispatch }) {
    const { challenge, sectorProfit } = rootState.businessModelFinder.values;
    if (!challenge || !sectorProfit) return;

    commit(MutationTypes.SET_RESOURCES, []);
    commit(MutationTypes.SET_LOADING, true);

    const apiUrlParams = generateUrlParamsString({
      challenge,
      sector: sectorProfit,
    });

    return API.getResourcesForInsights(apiUrlParams)
      .then((response) => {
        const resources = response.data.map(mapResourceDataToResource);
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, resources);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, []);
      });
  },
  [ActionTypes.GET_RESOURCES_FOR_CASE_STUDY]({ commit, dispatch }, params) {
    commit(MutationTypes.SET_RESOURCES, []);
    commit(MutationTypes.SET_LOADING, true);

    const apiUrlParams = generateUrlParamsString(params);

    return API.getResourcesForCaseStudy(apiUrlParams)
      .then((response) => {
        const resources = response.data.map(mapResourceDataToResource);
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, resources);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(
          "messages/ADD_ERROR_MESSAGE",
          "Sorry, something went wrong. Please try again.",
          { root: true }
        );
        commit(MutationTypes.SET_LOADING, false);
        commit(MutationTypes.SET_RESOURCES, []);
      });
  },
};

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState, Spark } from "./state";

// Getters types
export type Getters = {
  getIsLoading(state: LocalState): boolean;
  getRandomSparks(state: LocalState): Spark[];
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getRandomSparks: (state) => {
    const randomSparks = [...state.sparks];

    // reduce...
    while (randomSparks.length > 3) {
      const index = Math.floor(Math.random() * randomSparks.length);
      randomSparks.splice(index, 1);
    }

    // ...and shuffle
    randomSparks.sort(() => (Math.random() > 0.5 ? 1 : -1));
    return randomSparks;
  },
};

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["md:mx-px focus:outline-none transition", {
      // rounded styles
      'focus:outline-none text-white': _ctx.type === 'rounded',
      'bg-cebai-primary': _ctx.activeTab === _ctx.slug && _ctx.type === 'rounded',
      'bg-cebai-tertiary hover:bg-cebai-primary':
        _ctx.activeTab !== _ctx.slug && _ctx.type === 'rounded',
      'md:rounded-l-full': _ctx.first && _ctx.type === 'rounded',
      'md:rounded-r-full': _ctx.last && _ctx.type === 'rounded',

      // underline styles
      'border-b-4': _ctx.type === 'underline',
      'border-cebai-primary': _ctx.activeTab === _ctx.slug && _ctx.type === 'underline',
      'border-transparent': _ctx.activeTab !== _ctx.slug && _ctx.type === 'underline',
    }]),
    onClick: _cache[0] || (_cache[0] = () => _ctx.updateActiveTab(_ctx.slug))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
import { CaseStudySummaries } from "@/store/case-studies/state";

export interface Icon {
  name: string;
  slug: string;
  image: string;
}

export interface Icons {
  [index: string]: Icon;
}

export const businessModelIcons: Icons = {
  product: {
    name: "Product",
    slug: "product",
    image: "business-model-icon_product.svg",
  },
  matchmaking: {
    name: "Matchmaking",
    slug: "matchmaking",
    image: "business-model-icon_matchmaking.svg",
  },
  solutions: {
    name: "Solutions",
    slug: "solutions",
    image: "business-model-icon_solutions.svg",
  },
  "multi-sided": {
    name: "Multi-sided",
    slug: "multi-sided",
    image: "business-model-icon_multi-sided.svg",
  },
};

export type BusinessModel = {
  name: string;
  summary: string;
  video: string;
  detail: string;
  themes: string[];
  caseStudies: CaseStudySummaries;
};

export type BusinessModels = {
  [slug: string]: BusinessModel;
};

// Declare state
export type State = {
  isLoading: boolean;
  businessModels: BusinessModels;
};

// Create initial state
export const state = {
  isLoading: false,
  businessModels: {},
} as State;

export type Link = {
  title: string;
  link: string;
};

export type Label = {
  id: number;
  name: string;
  about: string;
  links: Array<Link>;
  insights: Array<Link>;
};

// Declare state
export type State = {
  isLoading: boolean;
  label: Label | null;
};

// Create initial state
export const state = {
  isLoading: false,
  label: {},
} as State;

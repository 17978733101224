import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState, initialState, SlideResponse } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.MOVE_TO_NEXT_SLIDE](state: S, slide: string): void;
  [MutationTypes.SET_VALUE](state: S, payload: SlideResponse): void;
  [MutationTypes.BACK](state: S): void;
  [MutationTypes.RESET_VALUE](state: S, slide: string): void;
  [MutationTypes.RESET_ALL](state: S): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.MOVE_TO_NEXT_SLIDE](state, slide) {
    state.slides.push(slide);
  },
  [MutationTypes.SET_VALUE](state, { slide, value }) {
    state.values[slide] = value;
  },
  [MutationTypes.BACK](state) {
    const slides = [...state.slides];
    const lastSlide = slides.pop();
    state.slides = slides;
    if (lastSlide) {
      state.values[lastSlide] = initialState.values[lastSlide];
    }
  },
  [MutationTypes.RESET_VALUE](state, slide) {
    state.values[slide] = initialState.values[slide];
  },
  [MutationTypes.RESET_ALL](state) {
    state.slides = [...initialState.slides];
    state.values = { ...initialState.values };
  },
};
